import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AgoraProject } from '../models/agora-project.model';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { convertSnaps } from '../shared/helpers/db-utils';
import { CompletedQuestionnaire } from '../models/questionnaire.model';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class AgoraApiService {
    constructor(private http: HttpClient, private firestore: AngularFirestore) {}

    // getAllProjects(): Observable<any> {
    //   return  this.http.get<any>(`${environment.agoraBackendIntegrationUrl}/getAllProjects`);
    // }

    // addProject(name: string): Observable<any> {
    //   const obj = {
    //     name,
    //     enable_sign_key: true
    //   }
    //   return this.http.post<any>(`${environment.agoraBackendIntegrationUrl}/project`, obj);
    // }

    // deleteProject(projectId: string): Observable<any> {
    //   return this.http.delete<any>(`${environment.agoraBackendIntegrationUrl}/project/?id=${projectId}`);
    // }

    // createProject(agoraProject: AgoraProject) {
    //   const agoraProjectDoc = this.firestore.collection('agoraProject').doc(agoraProject.id);
    //   return agoraProjectDoc.set(JSON.parse(JSON.stringify(agoraProject)));
    // }

    // getAllAgoraProjects(): Observable<AgoraProject[]> {
    //   return this.firestore
    //       .collection('agoraProject')
    //       .snapshotChanges()
    //       .pipe(map((snaps) => convertSnaps<AgoraProject[]>(snaps)));
    // }

    // deleteProjectFromFirebase(project: AgoraProject) {
    //   return this.firestore.doc(`agoraProject/${project.id}`).delete();
    // }
}
