import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-disclaimer-pakistan',
    templateUrl: './disclaimer-pakistan.component.html',
    styleUrls: ['./disclaimer-pakistan.component.css'],
})
export class DisclaimerPakistanComponent implements OnInit {
    constructor(public dialogRef: MatDialogRef<DisclaimerPakistanComponent>) {}

    ngOnInit(): void {}

    close() {
        this.dialogRef.close();
    }
}
