<div class="sidenav-hold">
    <div class="icon-menu mb-1" *ngIf="hasIconTypeMenuItem">
        <!-- Icon menu separator -->
        <div id="areaFrequentlyAccessed" class="mb-1 nav-item-sep">
            <!-- <mat-divider [ngStyle]="{ margin: '0 -24px' }"></mat-divider> -->
            <!-- <span class="text-muted icon-menu-title">{{ iconTypeMenuTitle }}</span> -->
        </div>
        <!-- Icon menu items -->
        <div class="icon-menu-item" *ngFor="let item of menuItems">
            <button
                *ngIf="!item.hidden && item.type === 'icon'"
                mat-raised-button
                [disabled]="item.disabled"
                [matTooltip]="item.tooltip"
                routerLink="/{{ item.state }}"
                routerLinkActive="mat-bg-primary"
            >
                <mat-icon>{{ item.icon }}</mat-icon>
            </button>
        </div>
    </div>

    <img class="aurogen-logo" src="assets/images/aurogen-logo.png" alt="" />

    <ul appDropdown class="sidenav">
        <li *ngFor="let item of menuItems" appDropdownLink routerLinkActive="open">
            <!-- <div id="areaMenu" class="nav-item-sep" *ngIf="item.type === 'separator'"> -->
            <!-- <mat-divider></mat-divider> -->
            <!-- <span class="text-muted">{{ item.name | translate }}</span> -->
            <!-- </div> -->
            <div *ngIf="!item.hidden && item.type !== 'separator' && item.type !== 'icon'" class="lvl1">
                <a
                    routerLink="/{{ item.state }}"
                    mat-raised-button
                    class="button"
                    appDropdownToggle
                    *ngIf="item.type === 'link'"
                    [ngClass]="menuItemClass(item)"
                >
                    <mat-icon>{{ item.icon }}</mat-icon>
                    <span class="item-name lvl1">{{ item.name | translate }}</span>
                    <span fxFlex></span>
                    <span
                        class="menuitem-badge mat-bg-{{ badge.color }}"
                        [ngStyle]="{ background: badge.color }"
                        *ngFor="let badge of item.badges"
                        >{{ badge.value }}</span
                    >
                </a>
                <a
                    class="button-style"
                    [href]="item.state"
                    appDropdownToggle
                    matRipple
                    *ngIf="item.type === 'extLink'"
                    target="_blank"
                >
                    <mat-icon>{{ item.icon }}</mat-icon>
                    <span class="item-name lvl1">{{ item.name | translate }}</span>
                    <span fxFlex></span>
                    <span
                        color="accent"
                        class="menuitem-badge mat-bg-{{ badge.color }}"
                        [ngStyle]="{ background: badge.color }"
                        *ngFor="let badge of item.badges"
                        >{{ badge.value }}</span
                    >
                </a>

                <!-- DropDown -->
                <a *ngIf="item.type === 'dropDown'" class="button-dropdown" appDropdownToggle matRipple>
                    <mat-icon>{{ item.icon }}</mat-icon>
                    <span class="item-name lvl1">{{ item.name | translate }}</span>
                    <span fxFlex></span>
                    <span
                        class="menuitem-badge mat-bg-{{ badge.color }}"
                        [ngStyle]="{ background: badge.color }"
                        *ngFor="let badge of item.badges"
                        >{{ badge.value }}</span
                    >
                    <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
                </a>
                <!-- LEVEL 2 -->
                <ul class="submenu lvl2" appDropdown *ngIf="item.type === 'dropDown'">
                    <li *ngFor="let itemLvL2 of item.sub" appDropdownLink routerLinkActive="open">
                        <a
                            routerLink="{{ item.state ? '/' + item.state : '' }}/{{ itemLvL2.state }}"
                            appDropdownToggle
                            mat-raised-button
                            class="button"
                            *ngIf="itemLvL2.type !== 'dropDown'"
                        >
                            <span class="item-name lvl2">{{ itemLvL2.name | translate }}</span>
                            <span fxFlex></span>
                        </a>

                        <a *ngIf="itemLvL2.type === 'dropDown'" appDropdownToggle matRipple>
                            <span class="item-name lvl2">{{ itemLvL2.name | translate }}</span>
                            <span fxFlex></span>
                            <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
                        </a>

                        <!-- LEVEL 3 -->
                        <ul class="submenu lvl3" appDropdown *ngIf="itemLvL2.type === 'dropDown'">
                            <li *ngFor="let itemLvL3 of itemLvL2.sub" appDropdownLink routerLinkActive="open">
                                <a
                                    routerLink="{{ item.state ? '/' + item.state : '' }}{{
                                        itemLvL2.state ? '/' + itemLvL2.state : ''
                                    }}/{{ itemLvL3.state }}"
                                    appDropdownToggle
                                >
                                    <span class="item-name lvl3">{{ itemLvL3.name | translate }}</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </li>
    </ul>
</div>
<!-- Terms and Conditions -->
<div class="sidenav-hold">
    <div class="icon-menu mb-1" *ngIf="hasIconTypeMenuItem">
        <div class="mb-1 nav-item-sep">
            <!-- <mat-divider [ngStyle]="{ margin: '0 -24px' }"></mat-divider> -->
            <!-- <span class="text-muted icon-menu-title">{{ iconTypeMenuTitle }}</span> -->
        </div>
        <div class="icon-menu-item">
            <button
                id="areaTermsAndConditions"
                mat-raised-button
                matTooltip="Click to view all Terms and Conditions"
                routerLinkActive="mat-bg-primary"
                (click)="openBottomSheet()"
            >
                Terms and Conditions
            </button>
        </div>
    </div>
</div>
<!-- <div class="sidenav-hold">
    <ul
        appDropdown
        class="sidenav"
        style="position: absolute; bottom: 0; padding-bottom: 10px; overflow: scroll; font-style: italic;"
    >
        <li>
            <div matRipple id="areaTermsAndConditions">
                <mat-icon></mat-icon>
                <span class="" (click)="openAdverseEventReporting()">Adverse Event Reporting</span>
            </div>
        </li>
        <li>
            <div matRipple>
                <mat-icon></mat-icon>
                <span class="" (click)="openDisclaimer()">Disclaimer</span>
            </div>
        </li>
        <li>
            <div matRipple>
                <mat-icon></mat-icon>
                <span class="" (click)="openPrivacyPolicy()">Privacy Policy</span>
            </div>
        </li>
        <li>
            <div matRipple>
                <mat-icon></mat-icon>
                <span class="" (click)="openTermsOfUse()">Terms of Use</span>
            </div>
        </li>
        <li>
            <div matRipple>
                <mat-icon></mat-icon>
                <span class="" (click)="openTechnicalAssistance()">Technical Assistance</span>
            </div>
        </li>
    </ul>
</div> -->
