import { Injectable } from '@angular/core';
import { User } from 'firebase/app';
import { AngularFirestore, AngularFirestoreDocument, Query } from '@angular/fire/firestore';
import { of, Observable, from } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserProfile } from 'app/models/user-profile.model';
import { catchError, filter, first, map, mergeMap, take, tap, toArray } from 'rxjs/operators';
import { RepDoctorService } from '../rep-doctor.service';
import { Rep } from 'app/models/rep.model';
import { RepDoctorDb } from 'app/models/rep-doctor-db.model';

@Injectable()
export class UserProfileService {
    userProfile: UserProfile;
    // userProfile$: Observable<UserProfile>;

    constructor(
        private snackBar: MatSnackBar,
        private db: AngularFirestore,
        private repDoctorService: RepDoctorService
    ) {}

    public fetchUserProfile(user: User) {
        if (user) {
            return this.db.doc<UserProfile>(`userProfile/${user.uid}`).valueChanges();
        } else {
            return of(null);
        }
    }

    updateUserProfile(userProfile: UserProfile) {
        if (userProfile.uid) {
            return this.db.doc('userProfile/' + userProfile.uid).update(userProfile);
        }
        return null;
    }

    createUserProfile(
        user: User,
        mpNumber: string,
        mpDetail,
        market: string,
        governmentOffical: string,
        hcpType: string,
        acceptConcent: boolean,
        acceptPrivacy: boolean,
        acceptPopi: boolean,
        repId: string,
        repName: string
    ) {
        // Check if Doctor has a Rep
        const userProfileRef: AngularFirestoreDocument<UserProfile> = this.db.doc(`userProfile/${user.uid}`);
        let isVerified = false;
        if (mpDetail.councilnumber1.length > 1) {
            isVerified = true;
        } else {
            isVerified = false;
        }

        const userProfile: UserProfile = {
            uid: user.uid,
            isActive: true,
            isSuspended: false,
            title: mpDetail.title,
            firstName: mpDetail.firstname,
            lastName: mpDetail.lastname,
            initials: mpDetail.initials,
            mpNumber: mpNumber,
            photoURL: '',
            gender: mpDetail.gender,
            email: user.email,
            country: mpDetail.country_description,
            language: mpDetail.language,
            town: mpDetail.town,
            physicalAddress1: mpDetail.physad1,
            physicalAddress2: mpDetail.physad2,
            physicalSuburb: mpDetail.physsuburb,
            physicalAddressCode: mpDetail.physcode,
            regionName: mpDetail.regionname,
            hcpType: hcpType,
            discipline: mpDetail.service,
            subRegionName: mpDetail.subregionname,
            cellNumber: mpDetail.tel1code + ' ' + mpDetail.tel1,
            phoneNumber: mpDetail.tel2code + ' ' + mpDetail.tel2,
            market: market,
            governmentOfficial: governmentOffical,
            healthCareProfessional: true,
            agreetoTermsOfUse: true,
            acceptConcent: acceptConcent,
            acceptPrivacy: acceptPrivacy,
            acceptPopi: acceptPopi,
            userRole: 'User',
            isVerified: isVerified,
            repId: repId,
            repName: repName,
            createdDate: new Date().toDateString(),
        };

        return userProfileRef.set(userProfile, { merge: true });
    }

    getUserProfiles(searchString = '', pageNumber = 0, pageSize = 2000): Observable<UserProfile[]> {
        return this.db
            .collection<UserProfile>('userProfile', (ref) => {
                let query: Query = ref;
                query = query
                    .orderBy('lastName', 'asc')
                    .limit(pageSize)
                    .startAfter(pageNumber * pageSize);
                if (searchString) {
                    query = query.where('lastName', '>=', searchString).where('lastName', '<=', searchString + '~');
                }
                return query;
            })
            .valueChanges({ idField: 'id' });
    }

    getUserProfilesRecent(searchString = '', pageNumber = 0, pageSize = 2000): Observable<UserProfile[]> {
        return this.db
            .collection<UserProfile>('userProfile', (ref) => {
                let query: Query = ref;
                query = query
                    .orderBy('lastName', 'asc')
                    .limit(pageSize)
                    .startAfter(pageNumber * pageSize);
                if (searchString) {
                    query = query.where('lastName', '>=', searchString).where('lastName', '<=', searchString + '~');
                }
                return query;
            })
            .valueChanges({ idField: 'id' });
        // .pipe(
        //     map((userProfiles) =>
        //         userProfiles.filter((profile) => profile.createdDate && profile.createdDate.length > 0)
        //     )
        // );
    }

    getUserProfilesByFirstNameRecent(searchString = '', pageNumber = 0, pageSize = 2000): Observable<UserProfile[]> {
        return this.db
            .collection<UserProfile>('userProfile', (ref) => {
                let query: Query = ref;
                query = query
                    .orderBy('firstName', 'asc')
                    .limit(pageSize)
                    .startAfter(pageNumber * pageSize);
                if (searchString) {
                    query = query.where('firstName', '>=', searchString).where('firstName', '<=', searchString + '~');
                }
                return query;
            })
            .valueChanges({ idField: 'id' })
            .pipe(
                map((userProfiles) =>
                    userProfiles.filter((profile) => profile.createdDate && profile.createdDate.length > 0)
                )
            );
    }

    getUserProfilesByEmailRecent(searchString = '', pageNumber = 0, pageSize = 2000): Observable<UserProfile[]> {
        return this.db
            .collection<UserProfile>('userProfile', (ref) => {
                let query: Query = ref;
                query = query
                    .orderBy('email', 'asc')
                    .limit(pageSize)
                    .startAfter(pageNumber * pageSize);
                if (searchString) {
                    query = query.where('email', '>=', searchString).where('email', '<=', searchString + '~');
                }
                return query;
            })
            .valueChanges({ idField: 'id' })
            .pipe(
                map((userProfiles) =>
                    userProfiles.filter((profile) => profile.createdDate && profile.createdDate.length > 0)
                )
            );
    }

    getUserProfilesByFirstName(searchString = '', pageNumber = 0, pageSize = 2000): Observable<UserProfile[]> {
        return this.db
            .collection<UserProfile>('userProfile', (ref) => {
                let query: Query = ref;
                query = query
                    .orderBy('firstName', 'asc')
                    .limit(pageSize)
                    .startAfter(pageNumber * pageSize);
                if (searchString) {
                    query = query.where('firstName', '>=', searchString).where('firstName', '<=', searchString + '~');
                }
                return query;
            })
            .valueChanges({ idField: 'id' });
    }

    getUserProfilesByEmail(searchString = '', pageNumber = 0, pageSize = 2000): Observable<UserProfile[]> {
        return this.db
            .collection<UserProfile>('userProfile', (ref) => {
                let query: Query = ref;
                query = query
                    .orderBy('email', 'asc')
                    .limit(pageSize)
                    .startAfter(pageNumber * pageSize);
                if (searchString) {
                    query = query.where('email', '>=', searchString).where('email', '<=', searchString + '~');
                }
                return query;
            })
            .valueChanges({ idField: 'id' });
    }

    getUserProfileByMpNumber(mpNumber): Observable<UserProfile[]> {
        return this.db
            .collection<UserProfile>('userProfile', (ref) => {
                let query: Query = ref;
                query = query.where('mpNumber', '==', mpNumber);
                return query;
            })
            .valueChanges({ idField: 'id' })
            .pipe(take(1));
    }

    isMpNumberisInUse(mpNumber: string) {
        return this.db
            .collection('userProfile', (ref) => ref.where('mpNumber', '==', mpNumber))
            .valueChanges()
            .pipe(first());
    }

    public handleError(error) {
        this.snackBar.open(error, 'close', { duration: 6000, panelClass: 'red-snackbar' });
    }

    // examples
    // repId = '0HkcycsdDUVfDgocaTxn'
    //

    updateRepNamesInUserProfiles(): Observable<any> {
        console.log('Starting to update user profiles with their corresponding rep names...');
        return this.db
            .collection<UserProfile>('userProfile', (ref) =>
                ref.where('repId', '!=', null).where('repName', '==', null)
            )
            .valueChanges({ idField: 'docId' })
            .pipe(
                tap((userProfiles) => console.log(`Fetched ${userProfiles.length} user profiles with repIds.`)),
                mergeMap((userProfiles) => userProfiles),
                mergeMap((userProfile) => {
                    if (!userProfile.repId || userProfile.repId.trim() === '') {
                        // console.log(`Skipping userProfile ID: ${userProfile.docId} due to invalid or empty repId.`);
                        return of(null); // Skip this userProfile
                    }
                    return this.db
                        .doc<Rep>(`reps/${userProfile.repId}`)
                        .valueChanges()
                        .pipe(
                            tap((rep) => {
                                if (!rep) {
                                    console.log(`No representative found for repId: ${userProfile.repId}`);
                                }
                            }),
                            map((rep) =>
                                rep
                                    ? {
                                          userProfileId: userProfile.docId,
                                          repName: rep.name,
                                      }
                                    : null
                            )
                        );
                }),
                filter((update) => update !== null && update.repName !== null),
                mergeMap((update) =>
                    this.db
                        .doc(`userProfile/${update.userProfileId}`)
                        .update({ repName: update.repName })
                        .then(() => ({
                            userProfileId: update.userProfileId,
                            updated: true,
                        }))
                        .catch((err) => {
                            console.error(`Failed to update userProfile ID: ${update.userProfileId}:`, err);
                            return { userProfileId: update.userProfileId, updated: false };
                        })
                ),
                tap((result) => {
                    if (result.updated) {
                        console.log(`Updated userProfile ID: ${result.userProfileId} with new repName.`);
                    } else {
                        console.log(`No update necessary for userProfile ID: ${result.userProfileId}`);
                    }
                }),
                catchError((err) => {
                    console.error('Error during the update process:', err);
                    return of({ error: err, status: 'Error' }); // Using 'of' to return an observable
                })
            );
    }

    // temp code
    // Fetch all users
    // async updateRegistrationDates() {
    //     let nextPageToken;
    //     do {
    //       // List batch of users, 1000 at a time.
    //       const listUsersResult = await admin.auth().listUsers(1000, nextPageToken);
    //       listUsersResult.users.forEach(async (userRecord) => {
    //         const uid = userRecord.uid;
    //         const registrationDate = userRecord.metadata.creationTime;

    //         // Assume the user profiles are stored in a collection named "userProfiles" in Firestore
    //         const userRef = admin.firestore().collection('userProfiles').doc(uid);

    //         // Update the registrationDate field
    //         await userRef.update({ registrationDate: registrationDate });
    //       });

    //       nextPageToken = listUsersResult.pageToken;
    //     } while (nextPageToken);
    //   }

    // Run the update
    //   updateRegistrationDates()
    //     .then(() => console.log('User profiles updated successfully'))
    //     .catch((error) => console.log('Error updating user profiles:', error));
    // }
}
