<div class="header-topnav mat-elevation-z2">
  <div class="container">
    <div class="topnav">
      <!-- App Logo -->
      <div class="topbar-branding">
        <img src="assets/images/logo.png" alt="" class="app-logo" />
      </div>

      <!-- <ul class="menu" *ngIf="!layoutConf.isMobile">
        <li *ngFor="let item of menuItems; let i = index;">
          <div *ngIf="item.type !== 'separator'" routerLinkActive="open">
            <a matRipple routerLink="/{{item.state}}" *ngIf="item.type === 'link'">
              <mat-icon>{{item.icon}}</mat-icon> 
              {{item.name | translate}}
            </a>
            <div *ngIf="item.type === 'dropDown'">
              <label matRipple for="drop-{{i}}" class="toggle"><mat-icon>{{item.icon}}</mat-icon> {{item.name | translate}}</label>
              <a matRipple><mat-icon>{{item.icon}}</mat-icon> {{item.name | translate}}</a>
              <input type="checkbox" id="drop-{{i}}" />
              <ul>
                <li *ngFor="let itemLvL2 of item.sub; let j = index;" routerLinkActive="open">
                  <a matRipple routerLink="{{item.state ? '/'+item.state : ''}}/{{itemLvL2.state}}" 
                  *ngIf="itemLvL2.type !== 'dropDown'">
                    <mat-icon *ngIf="itemLvL2.icon">{{itemLvL2.icon}}</mat-icon>  
                    {{itemLvL2.name | translate}}
                  </a>
                  
                  <div *ngIf="itemLvL2.type === 'dropDown'">
                    <label matRipple for="drop-{{i}}{{j}}" class="toggle">{{itemLvL2.name | translate}}</label>
                    <a matRipple><mat-icon *ngIf="itemLvL2.icon">{{itemLvL2.icon}}</mat-icon>  {{itemLvL2.name | translate}}</a>
                    <input type="checkbox" id="drop-{{i}}{{j}}" />
                     Level 3 
                    <ul>
                      <li *ngFor="let itemLvL3 of itemLvL2.sub" routerLinkActive="open">
                        <a matRipple routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state}}">
                          <mat-icon *ngIf="itemLvL3.icon">{{itemLvL3.icon}}</mat-icon>
                          {{itemLvL3.name | translate}}
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ul> -->
      <span fxFlex></span>
      <!-- End Navigation -->

      <!-- Language Switcher -->
      <!-- <mat-select 
      *ngIf="!layoutConf.isMobile"
      placeholder=""
      id="langToggle"
      [style.width]="'auto'"
      name="currentLang"
      [(ngModel)]="currentLang" 
      (selectionChange)="setLang()"
      class="topbar-button-right">
        <mat-option 
        *ngFor="let lang of availableLangs" 
        [value]="lang.code" ngDefaultControl>{{ lang.name }}</mat-option>
      </mat-select> -->
      <!-- Theme Switcher -->
      <!-- <button 
      mat-icon-button
      id="schemeToggle" 
      [style.overflow]="'visible'"
      matTooltip="Color Schemes"
      [matMenuTriggerFor]="themeMenu"
      class="topbar-button-right">
        <mat-icon>format_color_fill</mat-icon>
      </button>
      <mat-menu #themeMenu="matMenu">
        <mat-grid-list
        class="theme-list" 
        cols="2" 
        rowHeight="48px">
          <mat-grid-tile 
          *ngFor="let theme of egretThemes"
          (click)="changeTheme(theme)">
            <div mat-menu-item [title]="theme.name">
              <div [style.background]="theme.baseColor" class="egret-swatch"></div>
              <mat-icon class="active-icon" *ngIf="theme.isActive">check</mat-icon>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-menu> -->
      <!-- Notification toggle button -->
      <button mat-icon-button matTooltip="Notifications" (click)="toggleNotific()" [style.overflow]="'visible'"
        class="topbar-button-right">
        <mat-icon>notifications</mat-icon>
        <span class="notification-number mat-bg-warn">1</span>
      </button>
      <!-- Top left user menu -->
      <button mat-icon-button [matMenuTriggerFor]="accountMenu" class="topbar-button-right mr-1 img-button"></button>
      <mat-menu #accountMenu="matMenu">
        <button mat-menu-item [routerLink]="['user/profile']">
          <mat-icon>account_box</mat-icon>
          <span>Profile</span>
        </button>
        <!-- <button mat-menu-item [routerLink]="['/profile/settings']">
          <mat-icon>settings</mat-icon>
          <span>Account Settings</span>
        </button> -->
        <!-- <button mat-menu-item>
          <mat-icon>notifications_off</mat-icon>
          <span>Disable alerts</span>
        </button> -->
        <button mat-menu-item [routerLink]="['/sessions/signin']">
          <mat-icon>exit_to_app</mat-icon>
          <span>Sign out</span>
        </button>
      </mat-menu>
      <!-- Mobile screen menu toggle -->
      <button mat-icon-button class="mr-1" (click)="toggleSidenav()" *ngIf="layoutConf.isMobile">
        <mat-icon>menu</mat-icon>
      </button>
    </div>
  </div>
</div>