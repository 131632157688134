<embed
    src="./../../../assets/Website terms of use _ Pfizer Corporate Website _.pdf"
    frameborder="0"
    width="100%"
    height="90%"
/>
<!-- <div class="content"> -->

<div mat-dialog-actions>
    <button mat-raised-button class="mat-primary mb-1" (click)="close()">I Accept</button>
</div>
<!-- </div> -->
