<div class="content">
    <p><strong>Privacy Policy</strong></p>
    <p>
        Scope<br />This notice provides highlights of the privacy statement of Pfizer Limited, India
        (&lsquo;Pfizer&rsquo;). The privacy of your personal information is important to Pfizer. To better protect your
        privacy, we provide you with our Privacy Policy so that you will understand our commitment to you and to your
        privacy. This Privacy Policy describes what information we may collect about you; how we use your information;
        how we protect it; and what choices you have on how that information is used. At Pfizer, we understand that
        health is a very personal, private subject, and we want you to feel as comfortable as possible visiting our
        various websites and using their respective services. By accessing our website, you consent to the collection
        and use of information as set forth in this Privacy Policy.<br />If you have questions or concerns regarding
        this policy, you should first contact the Privacy Officer. As our privacy policy changes in significant ways, we
        will make every effort to notify you of the changes. Minor changes to the policy may occur that will not affect
        our use of individually identifiable or personal information. When the privacy policy changes in a way that
        significantly affects the way we handle personal information, we will not use the information we have previously
        gathered for any purpose not notified to you, without providing notice or obtaining your consent as and when
        appropriate.<br />The following articles make up our Privacy Policy. We hope that reading them gives you a clear
        idea of how we manage information regarding you. For immediate access to a particular topic, click on the title
        of that topic.<br />&bull; Personal information we collect<br />&bull; Use of your information<br />&bull; How
        Pfizer handles privacy internally<br />&bull; Updating your personal information and contacting Pfizer<br />When
        we refer to ourselves as "we" or "Pfizer," we mean our entire company, including any company that we control
        (for example, a subsidiary that we own). We may share information among companies that we own or control, and we
        may share information with our co-promotion partners, and companies working with Pfizer but all information we
        share will always be protected under the terms of this Privacy Policy.<br />Pfizer is not responsible for how
        other websites treat your privacy once you leave a Pfizer site.<br />Personal information we
        collect<br />Information
        we collect from visitors<br />Visitors to the Auro Space website including but not limited to applications etc.
        (collectively referred to as &ldquo;Website/ Digital/Electronic media&rdquo;) can access the website's
        login/registration page without disclosing any personally identifiable information. We do track information
        provided to us by your browser, including the website you came from (known as the referring URL), the type of
        browser you use, the time and date of access, and other information that does not personally identify you. On
        some of our sites, you may have to register to access portions of the site.<br />In addition, we gather
        information about you that is automatically collected by our web server, such as your IP address and domain
        name. Pfizer may use web server and browser information to individually customize its offerings and
        presentations if you submit your personal information.<br />Information we collect<br /> Registration on the
        site is optional and is at the discretion of the user.<br /> In case you wish to register, you will need to
        provide your identifying information such as First Name*, Last Name*, Email ID*, Address, State/Province, City,
        Profession*, Specialty*, Mobile Number*, Password*, Confirm Password*, GO/NGO Status*, Employer Details and
        Registration ID/Number. Fields marked with an * are mandatory.<br /> The information we capture as part of the
        registration process may be used by us for any future campaigns.<br />COOKIES<br />Cookies are a standard
        feature of websites that allow us to store small amounts of data on your computer about your visit to our Site.
        Cookies help us learn which areas of the Site are useful and which areas need improvement.
    </p>
    <p>
        You can find information about cookies that we use and change your settings for cookies and similar technologies
        by clicking on the Cookie Consent Tool in the lower right corner of each page of our Site. In addition, you can
        refuse or accept cookies from the Site at any time by activating the settings on your browser. Information about
        the procedure to follow in order to enable or disable cookies can be found on your Internet browser
        provider&rsquo;s website via your help screen. Please be aware that if cookies are disabled, your experience on
        the Site may be diminished, and not all features of the Site may operate as intended.
    </p>
    <p>We may also use technologies similar to cookies, such as:</p>
    <p>
        Google Analytics. Google Analytics is a service provided by Google, Inc. (&ldquo;Google&rdquo;). We use Google
        Analytics to collect anonymized statistics in order to improve the Site.
    </p>
    <p>
        Learn more about Google Analytics and privacy. You can selectively disable Google Analytics by installing the
        opt-out component provided by Google on your browser.
    </p>
    <p>
        How we use the information<br />Aggregate data<br />We may gather aggregate data from the information provided
        by the visitors to our websites and share your personally identifiable information with other Pfizer businesses
        or affiliates, our agents, contractor or business partners in order that they may perform services for us. We
        use our best efforts to assure that such third parties agree to abide by Pfizer privacy policies and enter into
        appropriate agreements to ensure that the third parties who receive your information implement security measures
        commensurate to our security standards.<br />If we discover that a third party working on behalf of Pfizer
        inappropriately disclosed personal information about any of our visitors, we will take immediate action to
        prevent further occurrences.<br />Other than as described above, Pfizer will not release personally identifiable
        information to a third party.<br />Protection of information<br />How Pfizer handles privacy internally<br />Our
        commitment to security<br />We have put in place appropriate physical, electronic, and managerial procedures to
        safeguard and help prevent unauthorized access, maintain data security, and correctly use the information we
        collect online. While we strive to protect your personal data against potential risks and exposures, the
        absolute security of data in the online sphere cannot be guaranteed. Hence, we ask you to support our
        information security efforts by not disclosing any data on our website that is particularly sensitive or not
        required in the specific context. Furthermore, you can help us protecting your personal data by signing off when
        you finished using log-in protected areas of the website.<br />Storage of information<br />Personally
        identifiable information you may share with us is stored on our database servers at Pfizer data centers (in
        whatever country they may be located) or hosted by third parties who have entered into agreements with us that
        require them to observe our Privacy Policy. We have a firewall and other technology to prevent individuals from
        accessing information without authorization. Data centers are designed to be physically secure and protected
        from unauthorized access by unauthorized persons.
    </p>
    <p>
        Third parties<br />In addition to aggregate information (discussed previously), we may share some kinds of
        information with third parties as described below.<br />&bull; Companies and people who work for us: Because we
        are a large company, we contract with other companies and individuals to help us provide services. For example,
        we may host some of our websites on another company's computers, hire technical consultants to maintain our
        web-based health sites, or work with companies to remove repetitive information from customer lists, analyze
        data, provide marketing assistance, and provide customer service. In order to perform their jobs, these other
        companies may have limited access to some of the personal information we maintain about our users. Other
        companies may collect information on our behalf through their sites. We require such companies to comply with
        the terms of our privacy policies, to limit their access to any personal information to the minimum necessary to
        perform their obligations, and not to use the information they may access for purposes other than fulfilling
        their responsibilities to us. We use our best efforts to limit other companies&rsquo; use of personally
        identifiable or health care information.<br />&bull; Business transfers: If we transfer a business unit (such as
        a subsidiary) or an asset (such as a website) to another company, we will require it to honor the terms of this
        Privacy Policy.<br />&bull; Legal requirements: We may release account and other personal information when we
        believe release is required to comply with law. We may release personal health information if, in our judgment
        after review by a lawyer, the release is compelled by law or regulation, or if the release may be necessary to
        prevent the death or serious injury of an individual.<br />&bull; Links to other websites: We may from time to
        time provide links to third party websites for your convenience and information. Such third party websites are
        not controlled by us. When you visit such other website you do so at your own risk. These sites may have their
        own privacy policies in place, which we recommend that you review if you visit any linked websites. We do not,
        assume any responsibility or liability for such third party websites, the content of such websites and their
        privacy practices, nor do we endorse them. We encourage you to familiarize yourself with the privacy statements
        provided by such other websites prior to providing them with information about you, or entering into any
        transactions on them.<br />&bull; Phishing: Identity theft and the practice currently known as "phishing" are of
        great concern to us. Safeguarding information to help protect you from identity theft is a priority for us. We
        do not and will not, at any time, request your credit or debit card information, bank account numbers, PIN
        numbers or passwords in a non-secure or unsolicited e-mail or telephone communication. Please refrain from
        providing such information through any non-secure medium.<br />Updating your personal information and contacting
        Pfizer<br />You can always contact us in order to (i) remove the personal information that you have provided to
        us from our systems, (ii) update the personal information that you have provided to us, and (iii) change your
        preferences with respect to marketing contacts by e-mailing our grievance officer Ms. Sandhya Amanna at
        02266932148 or write into contactus.india@pfizer.com or if available also logging into your account to update
        your information.<br />You may note that a request for deletion of your personal information may lead to the
        cancellation of your registration with our website, restriction of your access to certain features of the
        website, as well as cessation of certain services.<br />Changes in Privacy Policy<br />This privacy statement is
        effective as of 1st July 2020. From time to time we may update this Privacy Policy. Your continued use of our
        website constitutes an acceptance of the then-current Privacy Policy. We also encourage you to visit this page
        periodically to review any changes.
    </p>

    <div mat-dialog-actions>
        <button mat-raised-button class="mat-primary mb-1" (click)="close()">Close</button>
    </div>
</div>