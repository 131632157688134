import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { ConfigService } from 'app/services/config.service';
import { LegalService } from 'app/services/legal.service';

@Component({
    selector: 'app-bottom-sheet-share',
    templateUrl: './bottom-sheet-share.component.html',
    styleUrls: ['./bottom-sheet-share.component.scss'],
})
export class BottomSheetShareComponent implements OnInit {
    constructor(
        private configService: ConfigService,
        private bottomSheetRef: MatBottomSheetRef<BottomSheetShareComponent>,
        private legalService: LegalService
    ) {}

    ngOnInit() {}

    closeBottomSheet() {
        this.bottomSheetRef.dismiss();
    }

    openAdverseEventReporting() {
        this.legalService.openAdverseEventReporting(this.configService.userProfile.market);
    }

    openTermsOfUse() {
        this.legalService.openTermsOfUse(this.configService.userProfile.market);
    }

    openPrivacyPolicy() {
        this.legalService.openPrivacyPolicy(this.configService.userProfile.market);
    }

    openDisclaimer() {
        this.legalService.openDisclaimer(this.configService.userProfile.market);
    }

    openTechnicalAssistance() {
        this.legalService.openTechnicalAssistance(this.configService.userProfile.market);
    }

    showDisclaimer() {
        return this.legalService.showDisclaimer(this.configService.userProfile.market);
    }

    showTechnicalAssistance() {
        return this.legalService.showTechnicalAssistance(this.configService.userProfile.market);
    }

    showAdverseReporting() {
        return this.legalService.showAdverseReporting(this.configService.userProfile.market);
    }

    showPrivacyPolicy() {
        return this.legalService.showPrivacyPolicy(this.configService.userProfile.market);
    }
}
