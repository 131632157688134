import { Injectable } from '@angular/core';
import { AngularFirestore, Query } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { HcpLog } from 'app/models/hcp-log.model';
import { UserProfile } from 'app/models/user-profile.model';
import { ConfigService } from './config.service';

@Injectable()
export class HcpLogService {
    private collectionPath = 'hcpLogs';
    hcpLogs$: Observable<HcpLog[]>;

    constructor(private afs: AngularFirestore, private configService: ConfigService) {}

    getHcpLogs$(userProfile: UserProfile, pageNumber = 0, pageSize = 100): Observable<HcpLog[]> {
        return this.afs
            .collection<HcpLog>(this.collectionPath, (ref) => {
                let query: Query = ref;
                query = query
                    .orderBy('createdDate', 'asc')
                    .limit(pageSize)
                    .startAfter(pageNumber * pageSize);
                return query;
            })
            .valueChanges({ idField: 'uid' });
    }

    getHcpLogsByUid$(uid: string, pageNumber = 0, pageSize = 100): Observable<HcpLog[]> {
        // console.log(uid);

        return this.afs
            .collection<HcpLog>(this.collectionPath, (ref) => {
                let query: Query = ref;
                query = query
                    .orderBy('createdDate', 'asc')
                    .where('uid', '==', uid)
                    .limit(pageSize)
                    .startAfter(pageNumber * pageSize);
                return query;
            })
            .valueChanges({ idField: 'id' });
    }

    saveHcpLog(hcpLog: HcpLog) {
        if (hcpLog.uid) {
            return this.updateHcpLog(hcpLog);
        }
        return this.createHcpLog(hcpLog);
    }

    createHcpLog(hcpLog: HcpLog) {
        hcpLog = this.addMetaDataToHcpLog(hcpLog);
        return this.afs.collection(this.collectionPath).add(hcpLog);
    }

    addMetaDataToHcpLog(hcpLog: HcpLog): HcpLog {
        hcpLog.isActive = true;
        hcpLog.createdDate = new Date();
        hcpLog.uid = this.configService.userProfile.uid;
        hcpLog.firstName = this.configService.userProfile.firstName;
        hcpLog.lastName = this.configService.userProfile.lastName;
        hcpLog.mpNumber = this.configService.userProfile.mpNumber;
        hcpLog.email = this.configService.userProfile.email;
        hcpLog.country = this.configService.userProfile.country;
        hcpLog.initials = this.configService.userProfile.initials;
        hcpLog.hcpType = this.configService.userProfile.hcpType;
        hcpLog.discipline = this.configService.userProfile.discipline;
        hcpLog.cellNumber = this.configService.userProfile.cellNumber;
        hcpLog.phoneNumber = this.configService.userProfile.phoneNumber;
        return hcpLog;
    }

    updateHcpLog(hcpLog: HcpLog) {
        return this.afs.doc(this.collectionPath + '/' + hcpLog.uid).update(hcpLog);
    }

    deleteHcpLog(uid: string) {
        this.afs.doc(this.collectionPath + '/' + uid).delete();
    }
}
