import { Directive, ElementRef, Attribute, OnInit } from '@angular/core';

// tslint:disable-next-line: directive-selector
@Directive({ selector: '[fontSize]' })
export class FontSizeDirective implements OnInit {
  constructor( @Attribute('fontSize') public fontSize: string, private el: ElementRef) { }
  ngOnInit() {
    this.el.nativeElement.fontSize = this.fontSize;
  }
}
