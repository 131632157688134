import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-technical-assistance',
    templateUrl: './technical-assistance.component.html',
    styleUrls: ['./technical-assistance.component.css'],
})
export class TechnicalAssistanceComponent implements OnInit {
    constructor(public dialogRef: MatDialogRef<TechnicalAssistanceComponent>) {}

    ngOnInit(): void {}

    close() {
        this.dialogRef.close();
    }
}
