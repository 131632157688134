import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-terms-of-use-kenya',
    templateUrl: './terms-of-use-kenya.component.html',
    styleUrls: ['./terms-of-use-kenya.component.css'],
})
export class TermsOfUseKenyaComponent implements OnInit {
    constructor(public dialogRef: MatDialogRef<TermsOfUseKenyaComponent>) {}

    ngOnInit(): void {}

    close() {
        this.dialogRef.close();
    }
}
