<!-- <mat-list-item> -->
<button
    *ngIf="showAdverseReporting()"
    matRipple
    mat-raised-button
    width="100%"
    color="primary"
    (click)="openAdverseEventReporting()"
>
    Adverse Event Reporting
</button>
<button
    id="areaDisclaimer"
    *ngIf="showDisclaimer()"
    matRipple
    mat-raised-button
    width="100%"
    color="primary"
    (click)="openDisclaimer()"
>
    Disclaimer
</button>
<button
    *ngIf="showPrivacyPolicy()"
    matRipple
    mat-raised-button
    width="100%"
    color="primary"
    (click)="openPrivacyPolicy()"
>
    Privacy Policy
</button>

<!-- <button matRipple mat-raised-button width="100%" color="primary" (click)="openTermsOfUse()">Terms of Use</button> -->
<button
    *ngIf="showTechnicalAssistance()"
    matRipple
    mat-raised-button
    width="100%"
    color="primary"
    (click)="openTechnicalAssistance()"
>
    Technical Assistance
</button>
<button matRipple mat-raised-button width="100%" (click)="closeBottomSheet()">Close</button>
