import { Component, OnInit, Input } from '@angular/core';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'button-loading',
  templateUrl: './button-loading.component.html',
  styleUrls: ['./button-loading.component.scss']
})
export class ButtonLoadingComponent implements OnInit {

  // tslint:disable: no-input-rename
  @Input('loading') loading: boolean;
  @Input('btnClass') btnClass: string;
  @Input('raised') raised = true;
  @Input('loadingText') loadingText = 'Please wait';
  @Input('type') type: 'button' | 'submit' = 'submit';
  @Input('color') color: 'primary' | 'accent' | 'warn';

  constructor() {
  }

  ngOnInit() {
  }

}
