import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-terms-of-use-india',
    templateUrl: './terms-of-use-pakistan.component.html',
    styleUrls: ['./terms-of-use-pakistan.component.css'],
})
export class TermsOfUsePakistanComponent implements OnInit {
    constructor(public dialogRef: MatDialogRef<TermsOfUsePakistanComponent>) {}

    ngOnInit(): void {}

    close() {
        this.dialogRef.close();
    }
}
