import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Presenter } from 'app/models/presenter.model';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Questionnaire } from 'app/models/questionnaire.model';
import { Router } from '@angular/router';
import { CardBioComponent } from '../card-bio/card-bio.component';
import { egretAnimations } from 'app/shared/animations/egret-animations';
import { QuestionnaireAdminService } from 'app/services/questionnaire-admin.service';
import { UserProfile } from 'app/models/user-profile.model';
import { ConfigService } from 'app/services/config.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.scss'],
    animations: egretAnimations,
})
export class CardComponent implements OnInit, OnDestroy {
    @Input() questionnaire: Questionnaire;
    getAllCompletedQuestionnairesSubscription: Subscription;
    getSavedResponseSubscription: Subscription;
    Icon = 'assets/images/quiz.jpg';
    constructor(
        private router: Router,
        private dialog: MatDialog,
        private questionnaireAdminService: QuestionnaireAdminService,
        private configService: ConfigService
    ) {}

    ngOnInit() {
        this.getQuestionnaireStatusForUser(this.questionnaire, this.configService.userProfile);
    }

    openVideo(questionnaire: Questionnaire) {
        this.router.navigate(['cpd/video'], { state: questionnaire });
    }

    openDialog(presenter: Presenter) {
        const dialogRef: MatDialogRef<any> = this.dialog.open(CardBioComponent, {
            width: '500px',
            maxHeight: '70vh',
            disableClose: false,
            autoFocus: false,
            data: presenter,
        });
    }

    getVideoDuration(question: Questionnaire) {
        const myVideoPlayer = document.getElementById(question.questionnaireId) as HTMLVideoElement;
        if (myVideoPlayer) {
            const duration = myVideoPlayer.duration;
            const h = Math.floor(duration / 3600),
                m = Math.floor((duration % 3600) / 60),
                s = Math.floor(duration % 60);
            if (isNaN(h)) {
                return '';
            } else {
                return h.toFixed(0) + 'h :' + m.toFixed(0) + 'm :' + s.toFixed(0) + 's';
            }
        }
    }

    getActivityColor(activitStatus, background = true): string {
        if (this.questionnaire.overrideButtonText) {
            return 'default-color-background';
        }
        switch (activitStatus) {
            case 'Attend':
                return background ? 'attend-color-background' : 'attend-color';
                break;
            case 'Completed':
                return background ? 'completed-color-background' : 'completed-color';
                break;
            case 'In-Progress':
                return background ? 'in-progress-color-background' : 'in-progress-color';
                break;
        }
    }

    closeToExpire(expiryDate) {
        if (expiryDate) {
            if (this.daysToExpire(expiryDate) < 30) {
                return 'about-to-expire';
            }
        }
        return '';
    }

    daysToExpire(expiryDate: Date): number {
        const today = new Date();
        const endDate = new Date(expiryDate);
        return Math.floor(
            (Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()) -
                Date.UTC(today.getFullYear(), today.getMonth(), today.getDate())) /
                (1000 * 60 * 60 * 24)
        );
    }

    getExpiredStatus(status: string, expiryDate: Date): string {
        // overide button text
        if (this.questionnaire.overrideButtonText) {
            return this.questionnaire.overrideButtonText;
        }
        if (status === 'Attend' && this.daysToExpire(expiryDate) < 0) {
            return 'Expired';
        }
        return status;
    }

    // showCpdPoints(points) {
    //     switch (this.configService.userProfile.market) {
    //         case 'India':
    //             return false;
    //             break;
    //         case 'Pakistan':
    //             return false;
    //             break;
    //     }
    //     if (points === '0' || points === '' || !points) {
    //         return false;
    //     }
    //     return true;
    // }

    // Fixing
    getQuestionnaireStatusForUser(questionnaire: Questionnaire, userProfile: UserProfile) {
        // console.log('userProfile', userProfile);
        this.getAllCompletedQuestionnairesSubscription = this.questionnaireAdminService
            .getAllCompletedQuestionnaires()
            .subscribe(
                (qc) => {
                    const completed = qc.filter(
                        (c) => c.questionnaireId === questionnaire.questionnaireId && c.completedBy === userProfile.uid
                    );
                    if (completed.length > 0) {
                        const passed = completed.map((c) => c.score >= 70);
                        if (passed.length > 0) {
                            questionnaire.questionnaireStatus = 'Completed';
                        }
                    } else {
                        questionnaire.questionnaireStatus = 'Attend';
                    }
                    this.getSavedResponseSubscription = this.questionnaireAdminService
                        .getSavedResponse(questionnaire.questionnaireId, userProfile.uid)
                        .subscribe(
                            (res) => {
                                if (res.length > 0) {
                                    questionnaire.questionnaireStatus = 'In-Progress';
                                }
                            },
                            (error) => {
                                console.log('card/questionnaireAdminService', error);
                            }
                        );
                },
                (error) => {
                    console.log('card/getQuestionnaireStatusForUser', error);
                }
            );
    }

    ngOnDestroy() {
        this.getAllCompletedQuestionnairesSubscription.unsubscribe();
        this.getSavedResponseSubscription.unsubscribe();
    }
}
