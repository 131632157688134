import { Injectable } from '@angular/core';
import { AngularFirestore, Query } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { AdverseEvent } from 'app/models/adverse-event.model';
import { map } from 'rxjs/operators/map';
import { convertSnaps } from 'app/shared/helpers/db-utils';
import { getDate } from 'date-fns';
import { EmailService } from './email.service';
import { ConfigService } from './config.service';

@Injectable()
export class AdverseEventService {
    private collectionPath = 'adverseEvents';
    private adverseEventEmailTo = [
        'kudzai.chiumburu@aurobindo.com',
        'samantha.chengiah@aurogensa.co.za',
        'Keshmika.Narain@aurobindo.com',
        'berenice.campbell@aurobindo.com',
    ];
    //      'tebogo.letsitsi@aurobindo.com',

    private adverseEventEmailBcc = [
        'ruven.naidoo@dilowa.co.za',
        'mervin@jade-elephant.co.za',
        'nikita@jade-elephant.co.za',
    ];

    adverseEvents$: Observable<AdverseEvent[]>;

    constructor(
        private afs: AngularFirestore,
        private configService: ConfigService,
        private emailService: EmailService
    ) {}

    getAdverseEvents$(pageNumber = 0, pageSize = 100): Observable<AdverseEvent[]> {
        return this.afs
            .collection<AdverseEvent>(this.collectionPath, (ref) => {
                let query: Query = ref;
                query = query
                    .orderBy('createdDate', 'asc')
                    .limit(pageSize)
                    .startAfter(pageNumber * pageSize);
                return query;
            })
            .valueChanges({ idField: 'id' });
    }

    getAdverseEventsByUid$(uid: string, pageNumber = 0, pageSize = 100): Observable<AdverseEvent[]> {
        // console.log(uid);

        return this.afs
            .collection<AdverseEvent>(this.collectionPath, (ref) => {
                let query: Query = ref;
                query = query
                    .orderBy('createdDate', 'asc')
                    .where('uid', '==', uid)
                    .limit(pageSize)
                    .startAfter(pageNumber * pageSize);
                return query;
            })
            .valueChanges({ idField: 'id' });
    }

    getActiveAdverseEvents(): Observable<AdverseEvent[]> {
        return this.afs
            .collection(this.collectionPath)
            .snapshotChanges()
            .pipe(
                map((snaps) => {
                    let adverseEvents = convertSnaps<AdverseEvent[]>(snaps).filter((q) => q.isActive === true);
                    return adverseEvents;
                })
            );
    }

    saveAdverseEvent(adverseEvent: AdverseEvent) {
        if (adverseEvent.id) {
            return this.updateAdverseEvent(adverseEvent);
        }
        return this.createAdverseEvent(adverseEvent);
    }

    createAdverseEvent(adverseEvent: AdverseEvent) {
        adverseEvent = this.addMetaDataToAdverseEvent(adverseEvent);
        return this.afs
            .collection(this.collectionPath)
            .add(adverseEvent)
            .then((a) => {
                adverseEvent.id = a.id;
                this.sendAdverseEventEmail(adverseEvent);
            });
    }

    addMetaDataToAdverseEvent(adverseEvent: AdverseEvent): AdverseEvent {
        adverseEvent.isActive = true;
        adverseEvent.createdDate = new Date();
        adverseEvent.uid = this.configService.userProfile.uid;
        adverseEvent.firstName = this.configService.userProfile.firstName;
        adverseEvent.lastName = this.configService.userProfile.lastName;
        adverseEvent.mpNumber = this.configService.userProfile.mpNumber;
        adverseEvent.email = this.configService.userProfile.email;
        adverseEvent.country = this.configService.userProfile.country;
        adverseEvent.initials = this.configService.userProfile.initials;
        adverseEvent.hcpType = this.configService.userProfile.hcpType;
        adverseEvent.discipline = this.configService.userProfile.discipline;
        adverseEvent.cellNumber = this.configService.userProfile.cellNumber;
        adverseEvent.phoneNumber = this.configService.userProfile.phoneNumber;
        return adverseEvent;
    }

    sendAdverseEventEmail(adverseEvent: AdverseEvent) {
        this.emailService.sendEmail(
            this.adverseEventEmailTo,
            'New AdverseEvent:' + adverseEvent.createdDate,
            '',
            this.buildAdversEventEmailBody(adverseEvent),
            this.adverseEventEmailBcc
        );
    }

    buildAdversEventEmailBody(adverseEvent: AdverseEvent) {
        let html: string;

        html = ` <h1>Adverse Event Report</h1>
            <hr/>
            <h3>Adverse Events (AEs); synonym: Adverse experience</h3>
            <div class="mat-caption">
                Any untoward medical occurrence in a patient or clinical trial subject administered a medicinal product
                and which does not necessarily have a causal relationship with this treatment.
            </div>
            <br />

            <div class="mat-caption"><b>Untoward/undesirable occurrence might include:</b></div>
            <ul>
                <li>Disease or symptom temporally associated with the use of a medicinal product</li>
                <li>Injury</li>
                <li>Changes in symptoms</li>
                <li>Changes in laboratory test results</li>
                <li>Congenital abnormality</li>
            </ul>
            <div class="mat-caption">whether or not considered related to this medicinal product.</div>
            <br />

            <h3>Adverse drug reactions (ADR):</h3>
            <div class="mat-caption">
                Adverse drug reactions (ADR); synonyms Adverse reaction; Suspected adverse (drug) reaction, Adverse
                effect, Undesirable effect A response to a medicinal product which is noxious and unintended. Adverse
                reactions may arise from use of the product within or outside the terms of the marketing authorisation
                or from occupational exposure. Use outside the marketing authorisation includes off-label use, overdose,
                misuse, abuse and medication errors.
            </div>
            <br />
            <h3>Serious adverse reaction:</h3>
            <div class="mat-caption">
                An adverse reaction that results in death, is life-threatening, requires hospitalisation or prolongation
                of existing hospitalisation, results in persistent or significant disability or incapacity, or is a
                birth defects.
            </div>
            <hr/>
                <h3>Patient Details: </h3>
                    <div><b>Name / Initials: </b>${adverseEvent.name}</div>
                    <div><b>Gender: </b>${adverseEvent.gender}</div>
                    <div><b>Date of birth: </b>${adverseEvent.dateOfBirth}</div>
                    <div><b>Weight: </b>${adverseEvent.weight}</div>
                    <div><b>Height: </b>${adverseEvent.height}</div>
                    <hr/>
                    <br/>
                <h3>Patient History: </h3>
                <div><b>patientHistory: </b>${adverseEvent.patientHistory}</div>     
                
                
            <br/>
            <hr/>
            <h3>Other Information: </h3>
                <div><b>Suspected Information: </b>${adverseEvent.suspectedMedication}</div>
                <div><b>Suspected Reaction: </b>${adverseEvent.suspectedReaction}</div>

                <div><b>Outcome: </b>${adverseEvent.outcome}</div>
                <div><b>Seriousness: </b>${adverseEvent.seriousness}</div>
                <div><b>Start Date: </b>${adverseEvent.startDate}</div>
                <div><b>End Date: </b>${adverseEvent.endDate}</div>

                <div><b>Concomitant Medication: </b>${adverseEvent.concomitantMedication}</div>
                <div><b>Other Information: </b>${adverseEvent.eventDescription}</div>               
            <br/>
            <hr/>
            <h3> Reported By: </h3>
                <div><b>First Name: </b>${adverseEvent.firstName}</div>
                <div><b>Last Name: </b>${adverseEvent.lastName}</div>
                <div><b>Initials: </b>${adverseEvent.initials}</div>
                <div><b>Mp Number: </b>${adverseEvent.mpNumber}</div>
                <div><b>Email: </b>${adverseEvent.email}</div>
                <div><b>Country: </b>${adverseEvent.country}</div>
                <div><b>HCP Type: </b>${adverseEvent.hcpType}</div>
                <div><b>Discipline: </b>${adverseEvent.discipline}</div>
                <div><b>Cell Number: </b>${adverseEvent.cellNumber}</div>
                <div><b>Phone Number: </b>${adverseEvent.phoneNumber}</div>
                <div><p style="margin:0 0 25px 0;font-size:16px;line-height:24px;font-family:Arial,sans-serif;"><b>Reference:</b> ${adverseEvent.uid}</p><div>
            <hr/>
            Adverse Event Reference Code: ${adverseEvent.id}
            Accept Consent : ${adverseEvent.acceptConsent}

            <br/>
            <br/>
            <h3><b>Other information</b></div>
            <br />
            <div class="mat-caption">
                Aurogen South Africa (Pty) Ltd shall follow the basic principles that drive the data protection
                legislation, according to which:
            </div>
            <br />
            <ul>
                <li>The personal data should be processed lawfully, fairly and in a transparent manner</li>
                <li>The personal data collected would be adequate, relevant and limited to what is necessary</li>
                <li>
                    The personal data collected and processed would be kept accurate, where necessary it would be
                    rectified or erased
                </li>
                <li>
                    All appropriate security measures should be taken to protect the personal data collected for
                    pharmacovigilance purposes. The personal data should be protected from unauthorized access and
                    accidental data loss.
                </li>
            </ul>

        `;
        return html;
    }

    updateAdverseEvent(adverseEvent: AdverseEvent) {
        return this.afs.doc(this.collectionPath + '/' + adverseEvent.id).update(adverseEvent);
    }

    deleteAdverseEvent(id: string) {
        this.afs.doc(this.collectionPath + '/' + id).delete();
    }
}
