import { Injectable } from '@angular/core';
import { AngularFirestore, Query } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { Rep } from 'app/models/rep.model';
// import { map } from 'rxjs/operators/map';
// import { convertSnaps } from 'app/shared/helpers/db-utils';

@Injectable()
export class RepService {
    private collectionPath = 'reps';
    reps$: Observable<Rep[]>;

    constructor(private afs: AngularFirestore) {}

    getReps(returnInactiveReps = false, returnOnlyReps = false, pageNumber = 0, pageSize = 100): Observable<Rep[]> {
        return this.afs
            .collection<Rep>(this.collectionPath, (ref) => {
                let query: Query = ref;
                query = query
                    .orderBy('sortOrder', 'asc')
                    .orderBy('name', 'asc')
                    .limit(pageSize)
                    .startAfter(pageNumber * pageSize);
                if (!returnInactiveReps) {
                    query = query.where('isActive', '==', true);
                }
                if (returnOnlyReps) {
                    query = query.where('isHcp', '==', false);
                }
                return query;
            })
            .valueChanges({ idField: 'id' });
    }

    getRepByMpNumber(mpNumber: string): Observable<Rep[]> {
        return this.afs
            .collection<Rep>(this.collectionPath, (ref) => {
                let query: Query = ref;
                query = query.where('asNumber', '==', mpNumber);
                return query;
            })
            .valueChanges({ idField: 'id' });
    }

    saveRep(rep: Rep) {
        if (rep.id) {
            return this.updateRep(rep);
        }
        return this.createRep(rep);
    }

    createRep(rep: Rep) {
        return this.afs.collection('reps').add(rep);
    }

    updateRep(rep: Rep) {
        return this.afs.doc('reps/' + rep.id).update(rep);
    }

    deleteRep(id: string) {
        this.afs.doc('reps/' + id).delete();
    }
}
