<router-outlet></router-outlet>
<!-- <pdf-viewer [src]="./x.pdf" [render-text]="true" style="display: block"></pdf-viewer> -->
<!-- <pdf-viewer
    [src]="src"
    [rotation]="0"
    [original-size]="false"
    [show-all]="true"
    [fit-to-page]="false"
    [zoom]="1"
    [zoom-scale]="'page-width'"
    [stick-to-page]="false"
    [render-text]="true"
    [external-link-target]="'blank'"
    [autoresize]="true"
    [show-borders]="false"
    style="width: 100%; height: 600px"
></pdf-viewer> -->
