import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromUi from './shared/ui.reducer';
import * as fromAuth from './services/auth/auth.reducer';
// import * as fromProfile from './user/profile/profile.reducer';

export interface State {
    ui: fromUi.State;
    auth: fromAuth.State;
    //  userProfile: fromProfile.State;
}

export const reducers: ActionReducerMap<State> = {
    ui: fromUi.uiReducer,
    auth: fromAuth.authReducer,
    // userProfile: fromProfile.profileReducer,
};

export const getUiState = createFeatureSelector<fromUi.State>('ui');
export const getIsLoading = createSelector(getUiState, fromUi.getIsLoading);

export const getAuthState = createFeatureSelector<fromAuth.State>('auth');
// export const getUserProfile = createFeatureSelector<fromAuth.S
export const getIsAuth = createSelector(getAuthState, fromAuth.getIsAuth);
