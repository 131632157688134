import { Component, OnInit, Input } from '@angular/core';
import { CpdCertificate } from 'app/models/cpd-certificate.model';
import { egretAnimations } from 'app/shared/animations/egret-animations';

@Component({
    selector: 'app-certificate-card',
    templateUrl: './certificate-card.component.html',
    styleUrls: ['./certificate-card.component.scss'],
    animations: egretAnimations,
})
export class CertificateCardComponent implements OnInit {
    @Input() cpdCertificate: CpdCertificate;
    constructor() {}

    ngOnInit() {}

    onDownload(cpdCertificate: CpdCertificate) {
        const link = document.createElement('a');
        link.href = cpdCertificate.baseString;
        link.setAttribute('target', '_blank');
        link.setAttribute('download', `${cpdCertificate.fullName}_${cpdCertificate.title}_CDP.pdf`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    }
}
