<mat-card
    appMaterialElevation
    class="card-container p-1"
    [@animate]="{ value: '*', params: { scale: '.9', delay: '300ms' } }"
>
    <mat-card-content>
        <img
            *ngIf="questionnaire.showThumbnail"
            class="card-thumbnail"
            [src]="questionnaire.activityThumbnailUrl ? questionnaire.activityThumbnailUrl : quizIcon"
            alt=""
        />

        <div *ngIf="questionnaire.showTitle" class="card-title mat-title">{{ questionnaire.title }}</div>
        <div *ngIf="questionnaire.showCaption" class="card-capion">{{ questionnaire.caption }}</div>
        <!-- <div>{{ getVideoDuration(questionnaire) }}</div> -->

        <div *ngIf="questionnaire.showActivityType" class="card-activity-type">{{ questionnaire.activityType }}</div>

        <div *ngIf="questionnaire.showCPDPoints" class="card-points">
            <b>{{ questionnaire.overrideCPDPointsText }}</b> {{ questionnaire.cpdPointAccreditation }}
        </div>
        <section *ngIf="questionnaire.showExpiry">
            <div class="card-expiry-date card-expiry-date-center">
                <b>{{ questionnaire.overrideExpiryDateText }} </b>
                <span [class]="closeToExpire(questionnaire.expiryDate)">{{
                    questionnaire.expiryDate | date: 'dd MMM yyyy'
                }}</span>
            </div>
            <div class="card-expiry-date">
                <span>{{ questionnaire.webinarStartDateTime }}</span>
            </div>
        </section>
        <section *ngIf="questionnaire.presenter">
            <!-- 1st Presenter -->
            <img
                appMaterialElevation
                *ngIf="questionnaire.presenter && questionnaire.showPresenter"
                [class]="questionnaire.presenter2 ? 'bio-image-left' : 'bio-image-center'"
                [src]="questionnaire.presenter.photoURL ? questionnaire.presenter.photoURL : 'assets/images/avatar.jpg'"
                alt=""
                (click)="openDialog(questionnaire.presenter)"
            />
            <div
                *ngIf="questionnaire.showPresenter"
                [class]="questionnaire.presenter2 ? 'bio-name-left' : 'bio-name-center'"
                (click)="openDialog(questionnaire.presenter)"
            >
                {{ questionnaire.presenter.title }} {{ questionnaire.presenter.firstName }}
                {{ questionnaire.presenter.lastName }}
            </div>

            <!-- 2nd Presenter -->
            <img
                appMaterialElevation
                *ngIf="questionnaire.presenter2 && questionnaire.showPresenter"
                class="bio-image-right"
                [src]="
                    questionnaire.presenter2.photoURL ? questionnaire.presenter2.photoURL : 'assets/images/avatar.jpg'
                "
                alt=""
                (click)="openDialog(questionnaire.presenter2)"
            />

            <div
                class="bio-name-right"
                *ngIf="questionnaire.presenter2 && questionnaire.showPresenter"
                (click)="openDialog(questionnaire.presenter2)"
            >
                {{ questionnaire.presenter2?.title }} {{ questionnaire.presenter2?.firstName }}
                {{ questionnaire.presenter2?.lastName }}
            </div>
        </section>
        <!-- <i class="material-icons pdf-location">picture_as_pdf</i> -->
        <div *ngIf="questionnaire.showIconTray" class="icon-tray-position">
            <img *ngIf="questionnaire.enablePdf" class="pdf-icon" src="assets/images/pdf.png" />
            <img *ngIf="questionnaire.enableVideo" class="video-icon" src="assets/images/video.png" />
            <img *ngIf="questionnaire.enableHtml" class="html-icon" src="assets/images/html.png" />
            <img *ngIf="questionnaire.enableQuestions" class="question-icon" src="assets/images/questions.png" />
        </div>
        <button
            *ngIf="questionnaire.showButton"
            (click)="openVideo(questionnaire)"
            mat-raised-button
            mat-ripple
            class="card-button"
            [ngClass]="getActivityColor(getExpiredStatus(questionnaire.questionnaireStatus, questionnaire.expiryDate))"
        >
            {{ getExpiredStatus(questionnaire.questionnaireStatus, questionnaire.expiryDate) }}
        </button>
        <!-- <mat-icon
            class="card-icon"
            [ngClass]="
                getActivityColor(getExpiredStatus(questionnaire.questionnaireStatus, questionnaire.expiryDate), false)
            "
            >{{ questionnaire.questionnaireStatus == 'Completed' ? 'check_circle' : 'brightness_1' }}</mat-icon
        > -->
    </mat-card-content>
</mat-card>
