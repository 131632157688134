import { Component, Input } from '@angular/core';
import { Location } from '@angular/common';

@Component({
    selector: 'app-header-page',
    templateUrl: './header-page.component.html',
    styleUrls: ['./header-page.component.scss'],
})
export class HeaderPageComponent {
    @Input() enableBack: true;
    @Input() headerText: '';
    constructor(private location: Location) {}

    back() {
        this.location.back();
    }
}
