import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-terms-of-use',
    templateUrl: './terms-of-use.component.html',
    styleUrls: ['./terms-of-use.component.css'],
})
export class TermsOfUseComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<TermsOfUseComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public buttonName
    ) {}

    ngOnInit(): void {}

    close() {
        this.dialogRef.close();
    }
}
