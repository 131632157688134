import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { BrandService } from './brand.service';
import { ConfigService } from './config.service';

interface IMenuItem {
    type: string; // Possible values: link/dropDown/icon/separator/extLink
    name?: string; // Used as display text for item and title for separator type
    state?: string; // Router state
    icon?: string; // Material icon name
    tooltip?: string; // Tooltip text
    disabled?: boolean; // If true, item will not be appeared in sidenav.
    highlight?: boolean;
    hidden?: boolean;
    sub?: IChildItem[]; // Dropdown items
    badges?: IBadge[];
}
interface IChildItem {
    type?: string;
    name: string; // Display text
    state?: string; // Router state
    disabled?: boolean;
    highlight?: boolean;
    hidden?: boolean;
    icon?: string;
    sub?: IChildItem[];
}

interface IBadge {
    color: string; // primary/accent/warn/hex color codes(#fff000)
    value: string; // Display text
}

@Injectable()
export class NavigationService {
    iconMenu: IMenuItem[] = [
        {
            name: 'HUB',
            type: 'icon',
            tooltip: 'Hub',
            icon: 'home',
            state: 'hub',
        },
        {
            name: 'PROFILE',
            type: 'icon',
            tooltip: 'Profile',
            icon: 'person',
            state: 'user/profile',
        },
    ];

    subscriptions: Subscription;
    brands = [];
    // Icon menu TITLE at the very top of navigation.
    // This title will appear if any icon type item is present in menu.
    iconTypeMenuTitle = 'Frequently Accessed';
    // sets iconMenu as default;

    menuItems = new BehaviorSubject<IMenuItem[]>(this.iconMenu);
    // navigation component has subscribed to this Observable
    menuItems$ = this.menuItems.asObservable();
    isAdmin = false;
    isSuperAdmin = false;
    isSuperUser = false;
    showHCPLog = false;
    showAdminMenu = false;
    showSuperUserMenu = false;
    showRepManager = false;
    isStaff = false;
    isRep = false;
    isFFE = false;

    constructor(private configService: ConfigService, private brandService: BrandService) {
        // this.loadBrands();
        this.isSuperAdmin = this.configService.userProfile.userRole === 'Super Admin';
        this.isSuperUser = this.configService.userProfile.userRole === 'Super User';
        this.isAdmin = this.configService.userProfile.userRole === 'Admin';
        this.isRep = this.configService.userProfile.hcpType === 'Rep';
        this.isStaff = this.configService.userProfile.hcpType === 'Internal Staff';
        this.isFFE = this.configService.userProfile.userRole === 'FFE';

        this.showAdminMenu = this.isAdmin || this.isSuperAdmin;
        this.showSuperUserMenu = this.isAdmin || this.isSuperAdmin || this.isSuperUser;
        this.showRepManager = this.isAdmin || this.isSuperAdmin || this.isSuperUser || this.isFFE;
        this.showHCPLog = this.isRep || this.isStaff || this.isSuperAdmin;

        this.addAdminMenu();
    }

    // loadBrands() {
    //     this.subscriptions = this.brandService.getBrands().subscribe(
    //         (b) => {
    //             this.brands = [];
    //             b.forEach((brand) => {
    //                 this.brands.push({ name: brand.name, state: 'brand/' + brand.name });
    //             });
    //         },
    //         (error) => {
    //             // console.log('navigation.service / loadBrands: ', error);
    //         }
    //     );
    // }

    addAdminMenu() {
        const adminMenu: IMenuItem[] = [
            {
                name: 'THERAPEUTIC-AREAS',
                type: 'icon',
                tooltip: 'Therapeutic Arears',
                icon: 'home',
                state: 'therapeutic-areas',
            },
            {
                name: 'HUB',
                type: 'icon',
                tooltip: 'Hub',
                icon: 'apps',
                state: 'hub',
            },
            {
                name: 'PROFILE',
                type: 'icon',
                tooltip: 'Profile',
                icon: 'person',
                state: 'user/profile',
            },
            {
                name: 'CERTIFICATES',
                type: 'icon',
                tooltip: 'My Certificates',
                icon: 'note_add',
                state: 'cpd/certificates',
            },
            {
                type: 'separator',
                name: 'Main Items',
            },
            {
                name: 'ADMIN',
                type: 'dropDown',
                tooltip: 'Admin',
                icon: 'security',
                state: '',
                hidden: !this.showAdminMenu,
                sub: [
                    { name: 'User Manager', icon: 'contacts', type: 'link', state: 'admin/users' },
                    { name: 'Doctors DB', icon: 'contacts', type: 'link', state: 'admin/doctor' },

                    {
                        name: 'Therapeutic Area Manager',
                        icon: 'supervisor_account',
                        type: 'link',
                        state: 'admin/therapeutic-area',
                    },
                    {
                        name: 'Brand Manager',
                        icon: 'supervisor_account',
                        type: 'link',
                        state: 'admin/brand-manager',
                    },
                    {
                        name: 'FAQ Manager',
                        icon: 'supervisor_account',
                        type: 'link',
                        state: 'admin/faq-manager',
                    },
                    {
                        name: 'Rep Manager',
                        icon: 'supervisor_account',
                        type: 'link',
                        state: 'admin/rep-manager',
                    },
                    {
                        name: 'Rep Doctors',
                        icon: 'supervisor_account',
                        type: 'link',
                        state: 'admin/rep-doctors',
                    },
                    {
                        name: 'Presenter Manager',
                        icon: 'supervisor_account',
                        type: 'link',
                        state: 'admin/presenter',
                    },
                    {
                        name: 'ACTIVITY Manager',
                        icon: 'question_answer',
                        type: 'link',
                        state: 'admin/questionnaires-admin',
                    },
                    { name: 'Reports', icon: 'contacts', type: 'link', state: 'admin/analytics' },
                    { name: 'Webinar', icon: 'video_call', type: 'link', state: 'admin/webinar' },
                    {
                        name: 'Certificate Background',
                        icon: 'developer_board',
                        type: 'link',
                        state: 'admin/certificate-background',
                    },
                    {
                        name: 'Aurogen LIVE',
                        type: 'link',
                        icon: 'burst_mode',
                        state: 'video-conference',
                    },
                    {
                        name: 'Business Rules',
                        type: 'link',
                        icon: 'gavel',
                        state: 'admin/business-rules',
                    },
                ],
            },
            {
                name: 'Rep Manger',
                type: 'dropDown',
                tooltip: 'Manage users and Reps',
                icon: 'admin_panel_settings',
                state: '',
                hidden: !this.showRepManager,
                sub: [
                    { name: 'Rep Manager', icon: 'contacts', type: 'link', state: 'admin/rep-doctors' },
                    {
                        name: 'User Manager',
                        icon: 'contacts',
                        type: 'link',
                        state: 'admin/doctor-registrations',
                    },
                ],
            },
            {
                name: 'Bulk Email',
                type: 'dropDown',
                tooltip: 'Email',
                icon: 'email',
                state: '',
                hidden: !this.showAdminMenu,
                sub: [
                    { name: 'Compose', icon: 'inbox', type: 'link', state: 'email/compose' },
                    { name: 'Mailing List', icon: 'list', type: 'link', state: 'email/mailing-list' },
                ],
            },
            {
                name: 'My Info',
                type: 'dropDown',
                tooltip: 'My Info',
                icon: 'library_books',
                state: '',
                sub: [
                    { name: 'My Profile', state: 'user/profile' },
                    { name: 'My Certificates', state: 'cpd/certificates' },
                    // { name: 'My Reports', state: 'cpd/certificates' },
                ],
            },
            {
                name: 'HCP Log',
                type: 'link',
                tooltip: 'Log information against any HCP',
                icon: 'library_books',
                hidden: !this.showHCPLog,
                state: 'cpd/hcp-log',
            },
            {
                name: 'About Aurogen',
                type: 'link',
                tooltip: 'About Aurogen, all company information',
                icon: 'dashboard',
                state: 'hub/About Aurogen',
            },
            {
                name: 'Aurogen Staff',
                type: 'link',
                tooltip: 'Aurogen Staff',
                icon: 'contacts',
                state: 'user/reps/staff',
            },
            {
                name: 'Find a Rep',
                type: 'dropDown',
                tooltip: 'Click to view Reps',
                icon: 'contacts',
                state: '',
                sub: [
                    {
                        name: 'All Reps',
                        state: 'user/reps/all',
                    },
                    {
                        name: 'Gauteng Reps',
                        state: 'user/reps/gau',
                    },
                    {
                        name: 'Cape Town Reps',
                        state: 'user/reps/ct',
                    },
                    {
                        name: 'Kwazulu Natal Reps',
                        state: 'user/reps/kzn',
                    },
                    {
                        name: 'PE Reps',
                        state: 'user/reps/pe',
                    },
                    {
                        name: 'Bloemfontein Reps',
                        state: 'user/reps/bloemfontein',
                    },
                ],
            },
            {
                name: 'Therapeutic Areas',
                type: 'link',
                tooltip: 'Therapeutic Areas',
                icon: 'apps',
                state: 'therapeutic-areas',
            },
            {
                name: 'CPD/CME',
                type: 'dropDown',
                tooltip: 'Click to view Clinical, Etics, Centre of Excellence, ...',
                icon: 'local_pharmacy',
                state: '',
                sub: [
                    { name: 'Clinical Accredited', state: 'hub/clinical accredited' },
                    { name: 'Ethics Accredited', state: 'hub/ethics accredited' },
                    { name: 'Centre of Excellence', state: 'hub/centre of excellence' },
                    { name: 'Case Studies', state: 'hub/case studies' },
                ],
            },
            {
                name: 'Podcasts',
                type: 'link',
                tooltip: 'Podcasts',
                icon: 'cast_connected',
                state: 'hub/podcasts',
            },
            {
                name: 'Webinar',
                type: 'link',
                tooltip: 'Webinar',
                icon: 'subscriptions',
                state: 'hub/webinars',
            },
            {
                name: 'New Launches',
                type: 'link', // link/dropDown/icon/separator/extLink
                tooltip: 'New Launches',
                icon: 'fiber_new',
                highlight: true,
                disabled: false,
                state: 'hub/New Launches',
            },
            {
                name: 'Competitions',
                type: 'link',
                tooltip: 'Take a quiz',
                icon: 'wb_incandescent',
                state: 'hub/quizz',
            },
            {
                name: 'Our Participation',
                type: 'link',
                tooltip: 'Event and webinar photos and media',
                icon: 'camera_roll',
                state: 'hub/Our Participation',
            },
            {
                name: 'Lifestyle',
                type: 'link',
                tooltip: 'Lifestyle',
                icon: 'subtitles',
                state: 'hub/Lifestyle',
            },
            {
                name: 'Upcoming Events',
                type: 'link',
                tooltip: 'Upcoming Events',
                icon: 'event_note',
                state: 'hub/upcoming events',
            },
            {
                name: 'Coming Soon',
                type: 'link',
                tooltip: 'Coming Soon',
                icon: 'timer',
                disabled: true,
                state: 'cpd/coming-soon',
            },
            {
                name: 'Tools',
                type: 'link',
                tooltip: 'Tools for each HCP type',
                icon: 'build',
                state: 'hub/Tools',
            },
            {
                name: 'Adverse Event Reporting',
                type: 'link',
                tooltip: 'Adverse Event Reporting',
                icon: 'report',
                state: 'cpd/adverse-event-reporting',
            },
            {
                name: 'Product Complaints',
                type: 'link',
                tooltip: 'Product Complaints',
                icon: 'perm_camera_mic',
                state: 'cpd/product-complaints',
            },
            {
                name: 'FAQ',
                type: 'link',
                tooltip: 'Rep Cards',
                icon: 'question_answer',
                state: 'hub/faq',
            },
            {
                name: 'Help',
                type: 'link',
                tooltip: 'Auro Space assistance',
                icon: 'live_help',
                state: 'cpd/help',
            },
        ];
        this.menuItems.next(adminMenu);
    }

    // // Customizer component uses this method to change menu.
    // // You can remove this method and customizer component.
    // // Or you can customize this method to supply different menu for
    // // different user type.
    // publishNavigationChange(menuType: string) {
    //     switch (menuType) {
    //         case 'separator-menu':
    //             this.menuItems.next(this.separatorMenu);
    //             break;
    //         case 'icon-menu':
    //             this.menuItems.next(this.iconMenu);
    //             break;
    //         default:
    //             this.menuItems.next(this.plainMenu);
    //     }
    // }
}
