<div class="content">
    <p>
        <strong>Disclaimer</strong>
    </p>
    <p>
        The information contained herein (the-medical-professionals-portal.com) is provided for educational and
        informational purposes only and is for registered healthcare professionals only. Pfizer is unable to make any
        treatment recommendations for individual patients; all decisions regarding patient care must be made at the
        discretion of the healthcare professional, considering the unique characteristics of the patient, as well as the
        medical history and the clinical status of the patient.
    </p>
    <p>&nbsp;</p>
    <p>
        Pfizer, its officers and/or its employees do not accept or take any responsibility whatsoever for any loss,
        whether direct, indirect or consequential, which may arise from the misuse or inappropriate use of information
        contained on the pages and actions resulting therefrom (particularly where there has been reliance on outdated
        information which may have been made available through this site). Any liability that would or could arise as a
        result of the contents of these pages is hereby excluded to the fullest extent allowed by law.
    </p>
    <p>&nbsp;</p>
    <p>
        No warranty is given that any files, downloads or applications available via this web site are free of viruses
        which have the ability to corrupt your system.
    </p>
    <p>&nbsp;</p>
    <p>Any and all information is subject to change without notice.</p>
    <p>&nbsp;</p>
    <p>Copyright<sup>&copy;</sup> 2020 Pfizer Laboratories (Pty) Ltd. All rights reserved.</p>
    <p>&nbsp;</p>
    <p>
        The information provided in this site is intended only for registered medical practitioners authorised to
        practice in Pakistan.
    </p>

    <div mat-dialog-actions>
        <button mat-raised-button class="mat-primary mb-1" (click)="close()">Close</button>
    </div>
</div>
