import { Injectable } from '@angular/core';
import { AngularFirestore, Query } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { convertSnaps } from 'app/shared/helpers/db-utils';
import { CompletedQuestionnaire, Questionnaire, SavedResponse } from 'app/models/questionnaire.model';
import { UserProfile } from 'app/models/user-profile.model';

@Injectable()
export class QuestionnaireAdminService {
    selectedQuestionnaire: Questionnaire;

    constructor(private firestore: AngularFirestore) {}

    // setQuestionnaire(questionnaire: Questionnaire) {
    //     this.selectedQuestionnaire = questionnaire;
    // }

    // getQuestionnaire() {
    //     return this.selectedQuestionnaire;
    // }

    getQuestionnaires(): Observable<Questionnaire[]> {
        return this.firestore
            .collection('questionnaires')
            .snapshotChanges()
            .pipe(
                map((snaps) => {
                    let questionnaires = convertSnaps<Questionnaire[]>(snaps).filter((d) => d.isDeleted !== true);
                    return questionnaires;
                })
            );
    }

    getActiveQuestionnaires(
        userProfile: UserProfile,
        activityFilter: string,
        brandFilter: string
    ): Observable<Questionnaire[]> {
        return this.firestore
            .collection('questionnaires', (ref) => {
                let query: Query = ref;
                query = query.orderBy('sortOrder', 'asc');
                return query;
            })
            .snapshotChanges()
            .pipe(
                map((snaps) => {
                    let questionnaires = convertSnaps<Questionnaire[]>(snaps)
                        .filter((q) => q.isActive === true)
                        .filter((d) => d.isDeleted !== true)
                        .filter((m) => m.markets.find((c) => c === userProfile.market))
                        .filter((h) => h.hcpTypes.find((h) => h === userProfile.hcpType));

                    if (activityFilter) {
                        activityFilter = activityFilter.replace('-', ' ');
                        console.log('activityFilter:', activityFilter);
                        questionnaires = questionnaires.filter(
                            (f) => f.activityType.toLowerCase() === activityFilter.toLowerCase()
                        );
                    }
                    if (brandFilter) {
                        // brandFilter = brandFilter.replace('-', ' ');
                        // console.log('brandFilter:', brandFilter);
                        // console.log(brandFilter);
                        questionnaires = questionnaires.filter(
                            (f) => f.brand.toLowerCase() === brandFilter.toLocaleLowerCase()
                        );
                    }
                    console.log(questionnaires);
                    return questionnaires;
                })
            );
    }

    // getActiveReps(): Observable<Rep[]> {
    //     return this.afs
    //         .collection<Rep>(this.collectionPath, (ref) => {
    //             let query: Query = ref;
    //             query = query.orderBy('sortOrder', 'asc').orderBy('name', 'asc');
    //             return query;
    //         })
    //         .snapshotChanges()
    //         .pipe(
    //             map((snaps) => {
    //                 // let query: Query = snaps;
    //                 // query = query.orderBy('sortOrder', 'asc');
    //                 let reps = convertSnaps<Rep[]>(snaps).filter((q) => q.isActive === true);
    //                 return reps;
    //             })
    //         );
    // }

    createQuestionnaire(questionnaire: Questionnaire) {
        const questionnaireDocRef = this.firestore.collection('questionnaires').doc(questionnaire.questionnaireId);
        return questionnaireDocRef.set(JSON.parse(JSON.stringify(questionnaire)));
        // return this.firestore.collection('questionnaires').add(JSON.parse(JSON.stringify(questionnaire))).then(
        //     () => {
        //         console.log('questionnaire added');
        //     }
        // );
    }

    updateQuestionnaire(questionnaire: Questionnaire) {
        return this.firestore
            .doc('questionnaires/' + questionnaire.questionnaireId)
            .update(JSON.parse(JSON.stringify(questionnaire)));
    }

    deleteQuestionnaire(questionnaire: Questionnaire) {
        questionnaire.isDeleted = true;
        return this.updateQuestionnaire(questionnaire);
    }

    recordCompletedQuestionnaire(completedQuestionnaire: CompletedQuestionnaire) {
        const questionnaireDocRef = this.firestore
            .collection('completedQuestionnaire')
            .doc(completedQuestionnaire.completedQuestionnaireId);
        return questionnaireDocRef.set(JSON.parse(JSON.stringify(completedQuestionnaire)));
    }

    getAllCompletedQuestionnaires(): Observable<CompletedQuestionnaire[]> {
        return this.firestore
            .collection('completedQuestionnaire')
            .snapshotChanges()
            .pipe(map((snaps) => convertSnaps<CompletedQuestionnaire[]>(snaps)));
    }

    // fixCompletedQuestionnaires() {
    //     let completed;
    //     this.getAllCompletedQuestionnaires().subscribe((c) => {
    //         // console.log(c);
    //         let count = 0;
    //         completed = c.filter((f) => f.market === 'Test country');
    //         // console.log(completed.length);
    //         completed.forEach((q) => {
    //             q.market = 'South Africa';
    //             const questionnaireDocRef = this.firestore
    //                 .collection('completedQuestionnaire')
    //                 .doc(q.completedQuestionnaireId);
    //             questionnaireDocRef.update(q);
    //             console.log(count++);
    //             // this.firestore.doc('completedQuestionnaire/' + presenter.id).update(presenter);
    //             // this.recordCompletedQuestionnaire(q);
    //         });
    //     });
    // }

    saveResponses(savedResponse: SavedResponse) {
        const questionnaireDocRef = this.firestore
            .collection('savedResponse')
            .doc(`${savedResponse.questionnaireId}-${savedResponse.userId}`);
        return questionnaireDocRef.set(JSON.parse(JSON.stringify(savedResponse)));
    }

    getSavedResponse(questionId: string, userId: string) {
        return this.firestore
            .collection('savedResponse')
            .snapshotChanges()
            .pipe(
                map((snaps) =>
                    convertSnaps<SavedResponse[]>(snaps).filter(
                        (s) => s.userId === userId && s.questionnaireId === questionId
                    )
                )
            );
    }

    deleteResponse(savedResponse: SavedResponse) {
        return this.firestore.doc(`savedResponse/${savedResponse.questionnaireId}-${savedResponse.userId}`).delete();
    }
}
