import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-terms-of-use-india',
    templateUrl: './terms-of-use-india.component.html',
    styleUrls: ['./terms-of-use-india.component.css'],
})
export class TermsOfUseIndiaComponent implements OnInit {
    constructor(public dialogRef: MatDialogRef<TermsOfUseIndiaComponent>) {}

    ngOnInit(): void {}

    close() {
        this.dialogRef.close();
    }
}
