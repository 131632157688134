import { Timestamp } from '@firebase/firestore-types';

export class CpdCertificate {
    id: string;
    questionnaireId: string;
    baseString: string;
    title: string;
    userId: string;
    dateObtained: Date;
    market: string;
    fullName: string;
    isDeleted: boolean;
}
