<div class="content">
    <h1 mat-dialog-title>Terms of use</h1>
    <!-- <div mat-dialog-content> -->
    <p>
        This Agreement on the terms of use of Auro Space website (&ldquo;Web Site&rdquo; /
        &ldquo;Site&rdquo; / &ldquo;Digital/Electronic Media&rdquo;) is between User and Pfizer Limited
        (&lsquo;Pfizer&rsquo; or &ldquo;We&rdquo;).
    </p>
    <p>
        Terms of Use: This document states the terms of use on which you may make use of this Digital/Electronic Media.
        Please read these terms of use carefully before you start to use the Digital/Electronic Media and provide your
        acceptance when prompted.
    </p>
    <p>
        By using our Digital/Electronic media, you indicate that you accept these terms of use and that you agree to
        abide by them. If you do not agree to these terms of use, please refrain from, using the Digital/Electronic
        media.
    </p>
    <p>
        Pfizer may change these terms of use and other guidelines and rules posted on the Digital/Electronic Media from
        time to time.
    </p>
    <p>
        The information provided on this Digital/Electronic Media is for general informational and educational purposes
        only. By accessing and using this Digital/Electronic Media, you accept, without limitation or qualification,
        this Terms of Use Agreement. If you proceed and use this Web site, you assume full responsibility for your
        actions.
    </p>
    <p>
        Your access to and use of the information contained in the Digital/Electronic Media will be subject to the
        current version of the Terms of Use, rules and guidelines posted on the Digital/Electronic Media at the time of
        such use. If you breach any of the terms of use, your license to access or use this Digital/Electronic Media
        shall be liable for termination
    </p>
    <ol>
        <li>ACCESSING DIGITAL/ELECTRONIC MEDIA YOUR PERSONAL INFORMATION, PRIVACY AND COOKIES</li>
    </ol>
    <p>
        Access to our Digital/Electronic Media is permitted on a temporary basis and upon registration and agreement to
        our terms of use.
    </p>
    <p>
        ELIGIBILITY: Your membership and participation are completely voluntary. You represent and warrant that all
        registration information you submit is truthful and accurate. You agree to maintain the accuracy of such
        information. You further represent and warrant that you are eighteen (18) years of age or older.
    </p>
    <p>
        PRIVACY: Pfizer respects the privacy of its Digital/Electronic Media users. Please refer to Pfizer&rsquo;s
        Privacy Policy that explains users' rights and responsibilities with respect to information that is disclosed
        herein.
    </p>
    <p>
        From time to time, we may restrict access to some parts of Digital/Electronic Media, or its entirety. We also
        reserve the right to withdraw or amend the service we provide on our Digital/Electronic Media without notice. We
        will not be liable if for any reason our Digital/Electronic Media is unavailable at any time or for any period.
    </p>
    <p>
        COOKIES: Cookies are a standard feature of websites that allow us to store small amounts of data on your
        computer about your visit to our Site. Cookies help us learn which areas of the site are useful and which areas
        need improvement.&nbsp;<br /><br />
        You can refuse or accept cookies from the site at any time by activating the settings on your browser.
        Information about the procedure to follow in order to enable or disable cookies can be found on your Internet
        browser provider&rsquo;s website via your help screen. Please be aware that if cookies are disabled, your
        experience on the site may be diminished, and not all features of the site may operate as
        intended.&nbsp;<br /><br />
        We may also use technologies similar to cookies, such as: <br /><br /><strong>Google
            Analytics.</strong>&nbsp;Google Analytics is a service provided by Google, Inc. (&ldquo;Google&rdquo;). We
        use Google Analytics to
        collect anonymized statistics in order to improve the Site.&nbsp;<br /><br />
        Learn more about&nbsp;<a href="https://support.google.com/analytics/answer/6004245?hl=en-GB">Google Analytics
            and privacy</a>. You can selectively disable Google Analytics by installing the opt-out component provided
        by Google on your
        browser.
    </p>
    <ol start="2">
        <li>CONTENT</li>
    </ol>
    <p>
        You agree that access to and use of this Digital/Electronic Media and the content thereof is at your own risk.
        This is not intended in any manner to be a substitute to professional medical advice. Pfizer disclaims all
        warranties, express or implied, including warranties of merchantability or fitness for a particular purpose.
        Neither Pfizer nor any party involved in creating, producing or delivering this Digital/Electronic Media shall
        be liable for any damages, including without limitation, direct, incidental, consequential, indirect or punitive
        damages, arising out of access to, use of or inability to use this Digital/Electronic Media site, or any errors
        or omissions in the content thereof. This limitation includes damages to, or for any viruses that infect, your
        computer equipment.
    </p>
    <p>Non-Confidential Information</p>
    <p>
        Subject to any applicable terms and conditions set forth in our Privacy Policy, any communication or other
        material that you send to us through the Internet or post on the Digital/Electronic Media by electronic mail or
        otherwise, such as any questions, comments, suggestions or the like, is and will be deemed to be
        non-confidential and Pfizer shall have no obligation of any kind with respect to such information. Pfizer shall
        be free to use any ideas, concepts, know-how or techniques contained in such communication for any purpose
        whatsoever, including but not limited to, developing, manufacturing and marketing products.
    </p>
    <ol start="3">
        <li>USE OF Digital/Electronic Media. PERSONAL AND NON-COMMERCIAL USE LIMITATION</li>
    </ol>
    <p>
        Unless otherwise specified, this Digital/Electronic Media is for your personal and non-commercial use. You may
        not modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works
        from, transfer, or sell any information, software, products or services obtained from this Digital/Electronic
        Media. You may display and, subject to any expressly stated restrictions or limitations relating to specific
        material, electronically copy, download and print hard copy portions of the material from the different areas of
        the Digital/Electronic Media solely for your own non-commercial use. Any other use of materials on this
        Digital/Electronic Media, including but not limited to the modification, reproduction, distribution,
        republication, display or transmission of the content of this Site, without prior written permission of Pfizer
        is strictly prohibited. Harassment in any manner or form on the Site, including via e-mail and chat or by
        obscene or abusive language is strictly forbidden. Impersonation of others, including You may not upload to,
        distribute, or otherwise publish through the Digital/Electronic Media any content which is libelous, defamatory,
        obscene, threatening, invasive of privacy or publicity rights, abusive, illegal, or otherwise objectionable, or
        which may constitute or encourage a criminal offence, violate the rights of any party or otherwise give rise to
        liability or violate any law. You may not upload commercial content on this Digital/Electronic Media or use this
        Digital/Electronic Media to solicit others to join or become members of any other commercial online service or
        other organization.
    </p>
    <ol start="4">
        <li>Third Party Web sites and Links:</li>
    </ol>
    <p>
        The Digital/Electronic Media may contain links or references to other Web sites maintained by third parties over
        whom Pfizer has no control. Such links are provided merely as a convenience. Similarly, this Digital/Electronic
        Media may be accessed from third party links over whom Pfizer has no control. Pfizer makes no warranties or
        representations of any kind as to the accuracy, currency, or completeness of any information contained in such
        Web sites and shall have no liability for any damages or injuries of any kind arising from such content or
        information. Inclusion of any third party link does not imply an endorsement or recommendation by Pfizer in any
        manner whatsoever.
    </p>
    <ol start="5">
        <li>Medical Information:</li>
    </ol>
    <p>
        This Digital/Electronic Media may contain general information relating to various medical conditions and their
        treatment. Such information is provided for informational purposes only and you should use your discretion, re
        usage or non-usage of the information contained on this Web site.
    </p>
    <ol start="6">
        <li>Trademarks:</li>
    </ol>
    <p>
        All trademarks including product names, whether or not appearing in large print or with the trademark symbol,
        are trademarks of Pfizer, its affiliates, related companies or its licensors or joint venture partners, unless
        otherwise noted. The use or misuse of these trademarks or any other materials, except as permitted herein, is
        expressly prohibited and may be in violation of copyright law, trademark law, the law of slander and libel, the
        law of privacy and publicity, and communications regulations and statutes. Please be advised that Pfizer
        actively and aggressively enforces its intellectual property rights to the fullest extent of the law
    </p>
    <ol start="7">
        <li>COPYRIGHTS</li>
    </ol>
    <p>
        The compilation (meaning the collection, arrangement and assembly) of all content on this Digital/Electronic
        Media and all software used on this Digital/Electronic Media is subject to copyright protection and is the
        property of Pfizer. The compilation includes works that are licensed to Pfizer.
    </p>
    <p>
        The contents of this Digital/Electronic Media may not be copied other than for non- commercial individual
        reference with all copyright or other proprietary notices retained, and thereafter may not be recopied,
        reproduced or otherwise redistributed. Except as expressly provided above, you may not otherwise copy, display,
        download, distribute, modify, reproduce, republish or retransmit any information, text or documents contained in
        this Digital/Electronic Media or any portion thereof in any electronic medium or in hard copy, or create any
        derivative work based on such images, text or documents, without the express written consent of Pfizer. Nothing
        contained herein shall be construed as conferring by implication, estoppel or otherwise any license or right
        under any patent or trademark of Pfizer, or any third party.
    </p>
    <ol start="8">
        <li>RELATIONSHIP OF THE PARTIES</li>
    </ol>
    <p>
        In addition, no confidential or other relationship, fiduciary or otherwise, exists between You and Pfizer.
        Parties here act as an independent contractor and not as partner, joint venture, agent, employee or employer of
        the other and shall not bind nor attempt to bind the other to any contract.
    </p>
    <ol start="9">
        <li>MODIFICATION OF TERMS</li>
    </ol>
    <p>
        Pfizer may modify the terms of this Agreement at its sole discretion and without notice, which modification
        shall be effective immediately. Accordingly, we suggest that you check this page regularly for updated or
        additional guidelines
    </p>
    <ol start="10">
        <li>BREACH OF TERMS, SUSPENSION AND TERMINATION</li>
    </ol>
    <p>
        These terms and conditions are applicable to you upon your accessing this Digital/Electronic Media and/or
        completing the registration. These terms and conditions, or any of them, may be modified or terminated by Pfizer
        without notice at any time for any reason. The provisions relating to Copyrights and Trademarks, Disclaimer,
        Claims, Limitation of Liability, Indemnification, Applicable Laws, Arbitration and General, shall survive any
        termination.
    </p>
    <p>
        Failure to comply with this agreement constitutes a breach of the terms of use upon which you are permitted to
        use this Digital/Electronic Media, and may result in our taking all or any of the following actions: Immediate,
        temporary or permanent withdrawal of your right to use this Digital/Electronic Media. Legal proceedings against
        you for reimbursement of all costs on an indemnity basis (including, but not limited to, reasonable
        administrative and legal costs) resulting from the breach. Any further legal action as may be advised without
        prejudice to the above.
    </p>
    <p>
        We exclude liability for actions taken in response to breaches of these terms of use. The responses described in
        this provision are not limited, and we may take any other action we reasonably deem appropriate.
    </p>
    <ol start="11">
        <li>INDEMNITY</li>
    </ol>
    <p>
        You agree to indemnify, defend and hold harmless Pfizer, its officers, directors, employees, agents, suppliers
        and third party partners from and against all losses, expenses, damages and costs, including reasonable legal
        fees, resulting from any violation by you of these Terms of Use.
    </p>
    <ol start="12">
        <li>DISCLAIMER</li>
    </ol>
    <p>
        THIS DIGITAL/ELECTRONIC MEDIA AND THE INFORMATION, SERVICES, PRODUCTS AND MATERIALS CONTAINED ON THIS
        DIGITAL/ELECTRONIC MEDIA ARE PROVIDED ON AN "AS IS" BASIS WITH NO WARRANTY. PFIZER DISCLAIMS ALL REPRESENTATIONS
        AND WARRANTIES, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THE DIGITAL/ELECTRONIC MEDIA AND THE INFORMATION, ON
        THIS Digital/Electronic Media, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW. IN NO EVENT WILL PFIZER BE
        LIABLE FOR DAMAGES OF ANY KIND ARISING FROM THE USE OF THIS DIGITAL/ELECTRONIC MEDIA OR ANY INFORMATION OBTAINED
        ON THIS Digital/Electronic Media, INCLUDING DIRECT, INDIRECT, CONSEQUENTIAL, INCIDENTAL, SPECIAL OR PUNITIVE
        DAMAGES (EVEN IF PFIZER HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), TO THE FULLEST EXTENT PERMITTED BY
        LAW. ALL INFORMATION CONTAINED ON THIS DIGITAL/ELECTRONIC MEDIA SITE, INCLUDING INFORMATION RELATING TO MEDICAL
        AND HEALTH CONDITIONS, AND TREATMENTS, IS FOR INFORMATIONAL PURPOSES ONLY. IT IS OFTEN PRESENTED IN SUMMARY OR
        AGGREGATE FORM. IT IS NOT MEANT TO BE A SUBSTITUTE FOR THE ADVICE PROVIDED BY A PHYSICIAN OR OTHER MEDICAL
        PROFESSIONALS OR ANY INFORMATION CONTAINED ON OR IN ANY PRODUCT PACKAGING OR LABELS. YOU SHOULD NOT USE THE
        INFORMATION CONTAINED ON THIS DIGITAL/ELECTRONIC MEDIA FOR DIAGNOSING A HEALTH PROBLEM OR PRESCRIBING A
        MEDICATION. YOU SHOULD CAREFULLY READ ALL INFORMATION PROVIDED BY THE MANUFACTURERS OF THE PRODUCTS ON OR IN THE
        PRODUCT PACKAGING AND LABELS BEFORE USING ANY PRODUCT. ANY LINKS ON THIS DIGITAL/ELECTRONIC MEDIATO WEB SITES
        OPERATED BY THIRD PARTIES ARE PROVIDED FOR YOUR CONVENIENCE ONLY. PFIZER IS NOT RESPONSIBLE FOR THE CONTENT AND
        PERFORMANCE OF THESE WEB SITES OR FOR YOUR TRANSACTIONS WITH THEM AND OUR INCLUSION OF LINKS TO SUCH WEB SITES
        DOES NOT IMPLY ANY ENDORSEMENT OF THE MATERIAL ON SUCH WEB SITES/Digital/Electronic Media
    </p>
    <ol start="13">
        <li>ASSIGNMENT</li>
    </ol>
    <p>
        The User may not assign or transfer any of its rights or obligations under this agreement without Pfizer prior
        written consent, [such consent not to be unreasonably withheld or delayed]. Pfizer may assign or transfer its
        rights under this agreement without consent, provided that it gives advance notice to the User.
    </p>
    <ol start="14">
        <li>VARIATION AND WAIVER</li>
    </ol>
    <p>(i) User shall visit the Digital/Electronic Media to note any variation to the terms of use.</p>
    <p>
        (ii) A waiver of any right under this agreement is only effective if it is in writing and applies only to the
        party to whom the waiver is addressed and the circumstances for which it is given. No waiver shall be implied by
        taking or failing to take any other action.
    </p>
    <ol start="15">
        <li>SEVERANCE</li>
    </ol>
    <p>
        If any provision of this Agreement is held to be unlawful, void or unenforceable, then such provision shall be
        severable without affecting the enforceability of all remaining provisions. Pfizer reserves the right to alter
        or delete materials from this Digital/Electronic Media at any time at its discretion.
    </p>
    <ol start="16">
        <li>Void Where Prohibited:</li>
    </ol>
    <p>
        This Digital/Electronic Media and its contents are intended to comply with the laws and regulations in India.
        Other countries may have laws, regulatory requirements and medical practices that differ from those in India.
        This Digital/Electronic Media links to other sites produced by Pfizer's various operating divisions and
        subsidiaries, some of which are outside India. Those sites may have information that is appropriate only to that
        particular originating country
    </p>
    <ol start="17">
        <li>APPLICABLE LAW AND JURISDICTION</li>
    </ol>
    <p>
        The parties understand the English language and are fully aware of all terms and conditions contained herein. If
        any translation of this Agreement made, the English language version shall always continue to govern. This
        agreement shall be governed by and interpreted for any and all purposes in accordance with the internal laws of
        India applicable to contracts made, without reference to principles of conflicts of laws. Although the
        information on this Application is accessible to users outside of India, the information contained herein is
        intended for use only by Registered Medical Practitioner residing in India. Other countries may have laws,
        regulatory requirements and medical practices that differ from those in India. This Digital/Electronic Media may
        link to other Applications/ websites produced by Pfizer's various operating divisions and subsidiaries, some of
        which are outside of India. Those Applications may have information that is appropriate only to that particular
        originating country. The courts sitting in, or having principal jurisdiction over Mumbai shall have exclusive
        jurisdiction of all disputes hereunder and the Parties hereby agree that any such court shall be a proper forum
        for the determination of any dispute arising hereunder. Any cause of action or claim you may have with respect
        to the Digital/Electronic Media must be commenced within one (1) year after the claim or cause of action arises.
        Pfizer's failure to insist upon or enforce strict performance of any provision of these terms and conditions
        shall not be construed as a waiver of any provision or right. Neither the course of conduct between the parties
        nor trade practice shall act to modify any of these terms and conditions. Pfizer may assign its rights and
        duties under this Agreement to any party at any time without notice to you. Pfizer makes no representation that
        materials in the Digital/Electronic Media are appropriate or available for use in other locations, and access to
        them from territories where their content is illegal is prohibited.
    </p>
    <ol start="18">
        <li>User confirms that the User is a Registered Medical Practitioner authorized to practice in India.</li>
        <li>
            User consents to the collection, use and disclosure of User s&rsquo; personal information in accordance with
            Pfizer&rsquo;s Privacy Policy and Terms of Use
        </li>
        <li>User undertakes and confirms all information is true and correct</li>
    </ol>
    <div mat-dialog-actions>
        <button mat-raised-button class="mat-primary mb-1" (click)="close()">I Accept</button>
    </div>
</div>
<!-- </div> -->