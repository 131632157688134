import { Injectable } from '@angular/core';
import { UserProfile } from 'app/models/user-profile.model';

@Injectable({
    providedIn: 'root',
})
export class CountryLanguageService {
    private availableLangs = [
        {
            name: 'South Africa',
            code: 'en',
            flag: 'flag-icon-za',
        },
        {
            name: 'Nigeria',
            code: 'en',
            flag: 'flag-icon-ng',
        },
        {
            name: 'Kenya',
            code: 'en',
            flag: 'flag-icon-ke',
        },
        {
            name: 'Ghana',
            code: 'en',
            flag: 'flag-icon-gh',
        },
        {
            name: 'India',
            code: 'en',
            flag: 'flag-icon-in',
        },
        {
            name: 'Pakistan',
            code: 'en',
            flag: 'flag-icon-pk',
        },
        {
            name: 'Senegal',
            code: 'en',
            flag: 'flag-icon-sn',
        },
        {
            name: "Côte d'Ivoire",
            code: 'en',
            flag: 'flag-icon-ci',
        },
        {
            name: 'Cameroon',
            code: 'en',
            flag: 'flag-icon-cm',
        },
        {
            name: 'Burkina Faso',
            code: 'en',
            flag: 'flag-icon-bf',
        },
    ];
    currentLang = this.availableLangs[0];
    constructor() {}

    getCountryLanguage() {
        return this.availableLangs;
    }

    getCurrentLanguage(userProfile: UserProfile) {
        if (!userProfile) {
            return;
        }
        switch (userProfile.market) {
            case 'South Africa': {
                this.currentLang = {
                    name: userProfile.market,
                    code: 'en',
                    flag: 'flag-icon-za',
                };
                break;
            }
            case 'Nigeria': {
                this.currentLang = {
                    name: userProfile.market,
                    code: 'en',
                    flag: 'flag-icon-ng',
                };
                break;
            }
            case 'Kenya': {
                this.currentLang = {
                    name: userProfile.market,
                    code: 'en',
                    flag: 'flag-icon-ke',
                };
                break;
            }
            case 'Ghana': {
                this.currentLang = {
                    name: userProfile.market,
                    code: 'en',
                    flag: 'flag-icon-gh',
                };
                break;
            }
            case 'Ethiopia': {
                this.currentLang = {
                    name: userProfile.market,
                    code: 'en',
                    flag: 'flag-icon-et',
                };
                break;
            }
            case 'India': {
                this.currentLang = {
                    name: userProfile.market,
                    code: 'en',
                    flag: 'flag-icon-in',
                };
                break;
            }
            case 'Pakistan': {
                this.currentLang = {
                    name: userProfile.market,
                    code: 'en',
                    flag: 'flag-icon-pk',
                };
                break;
            }
            case 'Senegal': {
                this.currentLang = {
                    name: userProfile.market,
                    code: 'en',
                    flag: 'flag-icon-sn',
                };
                break;
            }
            // tslint:disable-next-line: quotemark
            case "Côte d'Ivoire": {
                this.currentLang = {
                    name: userProfile.market,
                    code: 'en',
                    flag: 'flag-icon-ci',
                };
                break;
            }
            case 'Cameroon': {
                this.currentLang = {
                    name: userProfile.market,
                    code: 'en',
                    flag: 'flag-icon-cm',
                };
                break;
            }
            case 'Burkina Faso': {
                this.currentLang = {
                    name: userProfile.market,
                    code: 'en',
                    flag: 'flag-icon-bf',
                };
                break;
            }
        }
        return this.currentLang;
    }
}
