import { Component, OnInit, Input } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { BottomSheetShareComponent } from '../bottom-sheet-share/bottom-sheet-share.component';
import { ConfigService } from 'app/services/config.service';

@Component({
    selector: 'app-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit {
    // tslint:disable no-input-rename
    @Input('items') public menuItems: any[] = [];
    @Input('hasIconMenu') public hasIconTypeMenuItem: boolean;
    @Input('iconMenuTitle') public iconTypeMenuTitle: string;

    constructor(private configService: ConfigService, private matBottomSheet: MatBottomSheet) {}
    ngOnInit() {}

    // Only for demo purpose
    addMenuItem() {
        this.menuItems.push({
            name: 'ITEM',
            type: 'dropDown',
            tooltip: 'Item',
            icon: 'done',
            state: 'material',
            sub: [
                { name: 'SUBITEM', state: 'cards' },
                { name: 'SUBITEM', state: 'buttons' },
            ],
        });
    }

    openBottomSheet(): void {
        this.matBottomSheet.open(BottomSheetShareComponent);
        // , { disableClose: true }
    }

    disableItemforIndiaAndPakistan(disabled: boolean) {
        if (this.configService.userProfile) {
            switch (this.configService.userProfile.market) {
                case 'South Africa':
                    return false;
                    break;
                case 'India':
                    return disabled;
                    break;
                case 'Pakistan':
                    return disabled;
                    break;
                default:
                    return false;
                    break;
            }
        }
    }

    menuItemClass(menuItem) {
        let itemClass = '';
        if (this.disableItemforIndiaAndPakistan(menuItem.disabled)) {
            itemClass += 'disabled ';
        }
        if (menuItem.highlight) {
            itemClass += 'highlight ';
        }
        return itemClass;
    }
}
