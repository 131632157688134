import { Injectable } from '@angular/core';
import { AngularFirestore, Query } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { Brand } from 'app/models/brand.model';
import { map } from 'rxjs/operators/map';
import { convertSnaps } from 'app/shared/helpers/db-utils';

@Injectable()
export class BrandService {
    private collectionPath = 'brands';
    brands$: Observable<Brand[]>;

    constructor(private afs: AngularFirestore) {}

    getBrands(
        therapeuticAreaFilter = '',
        returnOnlyActive = true,
        pageNumber = 0,
        pageSize = 100
    ): Observable<Brand[]> {
        let collectionRef = this.afs.collection<Brand>(this.collectionPath, (ref) => {
            let query: Query = ref;
            query = query
                .orderBy('sortOrder', 'asc')
                .orderBy('name', 'asc')
                .limit(pageSize)
                .startAfter(pageNumber * pageSize);
            if (returnOnlyActive) {
                query = query.where('isActive', '==', true);
            }
            return query;
        });
        return collectionRef.snapshotChanges().pipe(
            map((snaps) => {
                let brands = convertSnaps<Brand[]>(snaps);
                if (therapeuticAreaFilter !== '') {
                    brands = brands.filter((t) => t.therapeuticAreas.find((a) => a === therapeuticAreaFilter));
                }
                return brands;
            })
        );
    }

    saveBrand(brand: Brand) {
        if (brand.id) {
            return this.updateBrand(brand);
        }
        return this.createBrand(brand);
    }

    createBrand(brand: Brand) {
        brand.name = brand.name.trim();
        return this.afs.collection('brands').add(brand);
    }

    updateBrand(brand: Brand) {
        return this.afs.doc('brands/' + brand.id).update(brand);
    }

    deleteBrand(id: string) {
        this.afs.doc('brands/' + id).delete();
    }
}
