<!-- <div fxLayout="row wrap"> -->
<img
    class="card-bio-image"
    [src]="presenter.photoURL ? presenter.photoURL : 'assets/images/avatar.jpg'"
    alt=""
    height="120px"
    width="120px"
/>
<div class="card-bio-name">{{ presenter.title }} {{ presenter.firstName }} {{ presenter.lastName }}</div>
<p class="mat-body-1 card-presenter-biography mat-card-content">
    {{ presenter.biography }}
</p>
<!-- </div> -->
<!-- {{ presenter | json }} -->
