<h1 mat-dialog-title>Terms of use</h1>
<div mat-dialog-content>
    <p>
        <strong><u>TERMS OF USE</u></strong>
    </p>
    <p>
        Welcome to Pfizer's Nigeria Website for healthcare professionals. The information provided on this site is for
        general informational and educational purposes only. When we refer to ourselves as "we" or "Pfizer," we mean
        Pfizer Specialties Limited.
    </p>
    <p>
        <strong
            >Please read and review these Terms of Use carefully before accessing or using this Website. By accessing or
            using this site, you acknowledge that you have read, understood and agree to these Terms of Use. If you do
            not agree to the Terms of Use, you may not access or use the site.</strong
        >
    </p>
    <ol>
        <li>
            <strong>Use of Website.</strong> The information provided on this site is for general informational and
            educational purposes. Certain sections of this Website are intended for particular audiences, including
            Pfizer's employees, customers and shareholders, as well as members of the healthcare community and the
            general public. Your access to and use of the information contained in the Website is subject to these Terms
            of Use. By accessing and using this Website, you accept, without limitation or qualification, these Terms of
            Use.
        </li>
        <li>
            <strong>Content.</strong> Pfizer will use reasonable efforts to include accurate and up-to-date information
            on this Website but makes no warranties or representations of any kind as to its accuracy, currency or
            completeness. You agree that access to and use of this Website and the content thereof is at your own risk.
            Pfizer disclaims all warranties, express or implied, including warranties of merchantability or fitness for
            a particular purpose. Neither Pfizer nor any party involved in creating, producing or delivering this
            Website shall be liable for any damages, including, without limitation, direct, incidental, consequential,
            indirect or punitive damages, arising out of or in connection with access to, use of or inability to use
            this Website, or any errors or omissions in the content thereof. This limitation includes damages to, or for
            any viruses that infect, your computer equipment.
        </li>
        <li>
            <strong>Indemnification.</strong> You agree to indemnify, defend and hold harmless Pfizer, its officers,
            directors, employees, agents, suppliers and third-party partners from and against all losses, expenses,
            damages and costs, including, but not limited to, reasonable attorneys' fees, resulting from any violation
            by you of these Terms of Use.
        </li>
        <li>
            <strong>Privacy.</strong> Pfizer respects the privacy of its Website users. Please refer to Pfizer's Privacy
            Policy that explains users' rights and responsibilities with respect to information that is disclosed on
            this Website.
        </li>
        <li>
            <strong>Third Party Websites and Links.</strong> This Website may contain links or references to other
            Websites maintained by third parties over whom Pfizer has no control. Such links are provided merely as a
            convenience. Similarly, this Website may be accessed from third party links over whom Pfizer has no control.
            Pfizer makes no warranties or representations of any kind as to the accuracy, currency, or completeness of
            any information contained in such Websites and shall have no liability for any damages or injuries of any
            kind or nature arising from such content or information. Inclusion of any third party link does not imply an
            endorsement or recommendation by Pfizer.
        </li>
    </ol>
    <p>
        Also, please be aware that the security and privacy policies on these sites may be different than Pfizer's
        policies, so please read third party privacy and security policies closely.
    </p>
    <p>
        If you have any questions or concerns about the products and services offered on linked third party websites,
        please contact the third party directly.
    </p>
    <ol start="6">
        <li>
            <strong>Medical Information.</strong> This Website may contain general information relating to various
            medical conditions and their treatment. Such information is provided for informational purposes only and is
            not meant to be a substitute for advice provided by a doctor or other qualified healthcare professional.
            Website viewers should not use the information contained herein for diagnosing a health or fitness problem
            or disease. Patients should always consult with a doctor or other healthcare professional for medical advice
            or information about diagnosis and treatment and healthcare providers should make a diagnosis considering
            the unique characteristics of the patient.
        </li>
        <li>
            <strong>Forward Looking Statements.</strong> This Website may contain forward-looking statements about
            Pfizer's financial and operating performance, business plans and prospects, in-line products and products in
            development that involve substantial risks and uncertainties. Actual results could differ materially from
            the expectations and projections set forth in those statements. Such risks and uncertainties include, among
            other things, the uncertainties inherent in pharmaceutical research and development; decisions by regulatory
            authorities regarding whether and when to approve drug applications and supplemental drug applications as
            well as their decisions regarding labelling and other matters that could affect the availability or
            commercial potential of Pfizer products; competitive developments; the ability to successfully market both
            new and existing products; challenges to the validity and enforcement of Pfizer's patents; trends toward
            managed care and healthcare cost containment; governmental laws and regulations affecting healthcare,
            including, without limitation, regarding pharmaceutical product access, pricing and reimbursement; and
            general economic conditions, such as interest rate and foreign currency exchange rate fluctuations. Pfizer
            assumes no obligation to update any forward-looking statements as a result of new information or future
            events or developments.
        </li>
        <li>
            <strong>Non-Confidential Information.</strong> Subject to any applicable terms and conditions set forth in
            our Privacy Policy, any communication or other material that you send to us through the Internet or post on
            a Pfizer Website by electronic mail or otherwise, such as any questions, comments, suggestions or the like,
            is and will be deemed to be non-confidential and Pfizer shall have no obligation of any kind with respect to
            such information. Pfizer shall be free to use any ideas, concepts, know-how or techniques contained in such
            communication for any purpose whatsoever, including, but not limited to, developing, manufacturing and
            marketing products.
        </li>
        <li>
            <strong>Trademarks.</strong> All product names, whether or not appearing in large print or with the
            trademark symbol, are trademarks of Pfizer, its affiliates, related companies or its licensors or joint
            venture partners, unless otherwise noted. The use or misuse of these trademarks or any other materials,
            except as permitted herein, is expressly prohibited and may be in violation of copyright law, trademark law,
            the law of slander and libel, the law of privacy and publicity, and communications regulations and statutes.
            Please be advised that Pfizer actively and aggressively enforces its intellectual property rights to the
            fullest extent of the law.
        </li>
        <li>
            <strong>Copyrights.</strong> The entire contents of this Website are subject to copyright protection.
            Copyright &copy; 2019 Pfizer. The contents of Pfizer Websites may not be copied other than for
            non-commercial individual reference with all copyright or other proprietary notices retained, and thereafter
            may not be recopied, reproduced or otherwise redistributed. Except as expressly provided above, you may not
            otherwise copy, display, download, distribute, modify, reproduce, republish or retransmit any information,
            text or documents contained in this Website or any portion thereof in any electronic medium or in hard copy,
            or create any derivative work based on such images, text or documents, without the express written consent
            of Pfizer. Nothing contained herein shall be construed as conferring by implication, estoppel or otherwise
            any license or right under any patent or trademark of Pfizer, or any third party.
        </li>
        <li>
            <strong>Void Where Prohibited. </strong>This Website and its contents are intended to comply with the laws
            and regulations in the Republic of Nigeria. Although the information on this Website is accessible to users
            outside of the Republic of Nigeria, the information pertaining to Pfizer products is intended for use only
            by registered healthcare professionals of the Republic of Nigeria. Other countries may have laws, regulatory
            requirements and medical practices that differ from those in the Republic of Nigeria. This site links to
            other sites produced by Pfizer's various operating divisions, affiliates and subsidiaries, some of which are
            outside the Republic of Nigeria. Those sites may have information that is appropriate only to that
            particular originating country. Pfizer reserves the right to limit provision of its products or services to
            any person, geographic region or jurisdiction and/or to limit the quantities or any products or services we
            provide. Any offer for any product or service made on this Website is void where prohibited.
        </li>
        <li>
            <strong>Governing Laws.</strong> These Terms of Use and your use of the Website shall be governed by the
            laws of the Republic of Nigeria, without regard to its conflicts of laws principles. Any legal action or
            proceeding related to this Website shall be brought exclusively in a court of competent jurisdiction sitting
            in Nigeria, in the Republic of Nigeria.
        </li>
        <li>
            <strong>Miscellaneous.</strong> If any provision of these Terms of Use is held to be unlawful, void or
            unenforceable, then such provision shall be severable without affecting the enforceability of all remaining
            provisions. Pfizer reserves the right to alter or delete materials from this Website at any time at its
            discretion.
        </li>
    </ol>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>
        <strong><u>Cookies &amp; Privacy Policy</u></strong>
    </p>
    <p>
        Pfizer, the company behind
        <a href="https://grv.digitalpfizer.com/">https://grv.digitalpfizer.com/</a> &nbsp;respects your privacy and
        understands that health is a very personal and private subject. This Privacy Policy describes the personal
        information Pfizer collects about you on the Site; how we use that information; how we protect it; and the
        choices you may make with respect to your personal information. When we refer to ourselves as
        <strong><em>"we" </em></strong>or <strong><em>"Pfizer," </em></strong>we mean Pfizer Specialties Limited.&nbsp;
        Your visit to the Site is subject to this Privacy Policy and to our Terms of Use.
    </p>
    <p>
        Our Site may contain links to third-party websites/ content/ services that are not owned or controlled by
        Pfizer. Pfizer is not responsible for how these properties operate or treat your personal information so we
        recommend that you read the privacy policies and terms associated with these third party properties carefully.
    </p>
    <p>
        By continuing to use this Site you agree to our Terms of Use, Privacy Policy and that we may "collect, collate,
        process and/or store" your personal information (as defined in the Protection of Personal Information Act 4 of
        2013) for, amongst other things, (i) the purposes of providing you with access to the Site and the Site content;
        and (ii) for any of the purposes listed in this Site.
    </p>
    <p><strong>Information We Collect and Use</strong></p>
    <p>
        We collect personal information you choose to provide, e.g., through registrations, applications and surveys,
        and in connection with your inquiries. In addition, we may gather information about you automatically through
        your use of the Site, e.g., your IP address and how you navigate our Site. See also, the Section below on
        Cookies and Other Tools.
    </p>
    <p><strong>How We Use Personal Information</strong></p>
    <p>We may use your personal information in the following ways:</p>
    <p><strong>To Serve You:</strong></p>
    <p>
        We may use your personal information to respond to your inquiries and provide the products and services you
        request. We also use your information to deliver the content and services we believe you will find the most
        relevant. For example, a female user may receive information on products targeted at women that a male user will
        not see; a user who we know is a healthcare professional who treats arthritis may receive information relating
        to new arthritis therapies. We will also use your information to provide customer service and support.
    </p>
    <p><strong>To Serve Third Parties You Ask Us to Serve:</strong></p>
    <p>
        Our websites may offer various tools and functionality. Some of these are provided by Pfizer while others are
        provided by third parties. For example, Pfizer may provide functionality on the Site that will allow you to
        forward and share certain content with a friend or colleague. In addition, the Site may also offer third party
        sharing functionality such as third party social media widgets/tools/buttons. If you choose to use Pfizer
        sharing functionality to refer someone to our content, products or services, we will use your information and
        that of the individual you are sharing with to send the content or link you request, but we do not access, store
        or use the information relating to the individual you wish to share with other than in connection with your
        request. If you use third party sharing functionality, including social media widgets/tools/buttons, such use is
        subject to the third party&rsquo;s privacy policy and terms. As with all links to non-Pfizer
        websites/content/services, we recommend that you read the privacy policies and terms associated with these third
        party properties carefully.
    </p>
    <p><strong>To Validate Your Ability to Access / Use Certain Products, Services and Information:</strong></p>
    <p>
        Some products, services and information we offer may only be suitable for/accessible to individuals meeting
        certain eligibility or other criteria. In such cases, we may verify that you meet such criteria. For example,
        certain information relating to our products is only intended for and accessible to licensed healthcare
        professionals and we may use information we collect directly from you and from outside sources to validate your
        licensure and eligibility to view such information.
    </p>
    <p><strong>In Accordance with Special Program Terms:</strong></p>
    <p>
        On occasion, Pfizer may offer special programs and activities via the Site (<strong
            ><em>&ldquo;Special Programs&rdquo;</em></strong
        >) that have specific terms, privacy notices and/or informed consent forms that explain how any personal
        information you provide will be processed in connection with the Special Program (<strong
            ><em>&ldquo;Special Program Terms&rdquo;</em></strong
        >). Pfizer will honour those Special Program Terms with respect to your personal information. Some of these
        Special Programs may involve sharing information with a third party and Pfizer will provide you with notice of
        this. You may be offered the opportunity to agree to or opt-out of the sharing of your information with such
        third party. But, in some instances, it may be necessary to agree to sharing with a third party in order to
        participate in a particular Special Program. If you consent/do not opt-out, we will share your information with
        the third party; and the personal information you provide may be used by the third party in accordance with the
        Special Program Terms and, unless provided otherwise in such Special Program Terms, for their own purposes in
        accordance with their own policies/terms. We strongly suggest you review both the Special Program Terms and any
        applicable third-party policies/terms before participating.
    </p>
    <p><strong>To Improve Products and Services and Protect Patients and Consumers:</strong></p>
    <p>
        We use the information you provide to better understand how our products and services impact you and those you
        care for and to further develop and improve our products and services. For example, you may be invited to
        participate in a survey relating to our products or services; healthcare professionals may be invited to
        participate in various programs or panels of experts. In addition, we use the information you provide to comply
        with our regulatory monitoring and reporting obligations, including those related to adverse events, product
        complaints and patient safety.
    </p>
    <p><strong>In Aggregated or De-identified Form:</strong></p>
    <p>
        We may aggregate and/or de-identify data about visitors to our Site for various business purposes, including
        product and service development and improvement activities.
    </p>
    <p><strong>How We Share Your Personal Information</strong></p>
    <p>Pfizer may share your personal information as follows:</p>
    <p><strong>In Connection with our Service</strong></p>
    <p>For one or more of the above purposes, Pfizer may disclose personal information to:</p>
    <ul>
        <li>
            its affiliates within or outside of Nigeria, in which case your personal information will be collected,
            used, disclosed, managed and stored in accordance with this policy; and
        </li>
        <li>
            third parties within or outside of Nigeria &ndash; see the With Service Providers and Business Partners
            section; and
        </li>
        <li>
            regulatory authorities, within or outside of Nigeria, and ethics committees as part of a clinical trial
            evaluation, monitoring or inspection process.
        </li>
    </ul>
    <p><strong>In Connection with Business Transfers:</strong></p>
    <p>
        If we sell or transfer a business unit (such as a subsidiary) or an asset (such as a website) to another
        company, we will share your personal information with such company and require such company to use and protect
        your personal information consistent with this Privacy Policy.
    </p>
    <p><strong>With Service Providers and Business Partners:</strong></p>
    <p>
        We may retain other companies and individuals to perform services on our behalf and we may collaborate with
        other companies and individuals with respect to particular products or services (collectively, &ldquo;<strong
            ><em>Providers</em></strong
        >&rdquo;). Examples of Providers include data analysis firms, credit card processing companies, customer service
        and support providers, email and SMS vendors, web hosting and development companies and fulfilment companies.
        Providers also include our co-promote partners for products that we jointly develop and/or market with other
        companies. Some Providers may collect information on our behalf on our Site. These third parties may be provided
        with access to personal information needed to perform their functions, but they may not use such information
        other than on our behalf and subject to contracts that protect the confidentiality of the information. In
        addition, as noted above, we may share information with third parties in accordance with Special Program Terms,
        unless you opt-out/do not consent.
    </p>
    <p><strong>To Comply with Law/Protect:</strong></p>
    <p>
        We reserve the right to disclose your personal information as required by law, when we believe disclosure is
        necessary to comply with a regulatory requirement, judicial proceeding, court order, or legal process served on
        us, or to protect the safety, rights, or property of our customers, the public or Pfizer.
    </p>
    <p><strong>In the Aggregate/De-identified:</strong></p>
    <p>
        Pfizer may also disclose aggregate or de-identified data that is not personally identifiable with third parties.
        Aggregate data is created by pooling information about individuals and describing the data in the aggregate,
        <em>e.g.</em>, 20% of customers who clicked on the X page participated in the survey.
    </p>
    <p><strong>Cookies and Other Tools</strong></p>
    <p>
        Pfizer and its business partners collect information about you by using cookies, tracking pixels and other
        technologies (collectively, &ldquo;<strong><em>Tools</em></strong
        >&rdquo;). We use this information to better understand, customize and improve user experience with our
        websites, services and offerings. For example, we use web analytics services that leverage these Tools to help
        us to understand how visitors engage with and navigate our Site, e.g., how and when pages in a site are visited
        and by how many visitors. We are also able to offer our visitors a more customized, relevant experience on our
        sites using these Tools by delivering content and functionality based on your preferences and interests. If you
        have provided us with personal information, e.g., through a registration or a request for certain materials, we
        may associate this personal information with information gathered through the Tools. This allows us to offer
        increased personalization and functionality.
    </p>
    <p>
        Further as part of the Tools, we may use other tracking systems like pixel-tags. Pixel tags, sometimes called
        web beacons, are similar in function to cookies but because of their insignificant size, they are not visible.
        However, they are used to pass certain information to our servers to personalize our websites and to track your
        usage across other Pfizer websites. In addition, we may also use pixel tags in our HTML based emails.
    </p>
    <p>
        In addition, certain third parties may deliver cookies to your computer for a variety of reasons. For example,
        we sometimes use Google Analytics and/or Web Trend which are web analytics tools that help us understand how
        visitors engage with our websites. Any third party links or advertising on our site may also use cookies; you
        may receive these cookies by clicking on the link to the third party site or advertising. We do not control the
        collection or use of information by these third parties, and these third party cookies are not subject to this
        Privacy Policy. You should contact these companies directly if you have any questions about their collection
        and/or use of information.
    </p>
    <p>
        Your web browser can be set to allow you to control whether you will accept cookies, reject cookies, or to
        notify you each time a cookie is sent to your browser. If your browser is set to reject cookies, websites that
        are cookie-enabled will not recognize you when you return to the website, and some website functionality may be
        lost. The Help section of your browser may tell you how to prevent your browser from accepting cookies. If you
        are using a smartphone, set your preferences through the &ldquo;Settings&rdquo; app of your smartphone and/or
        the browser(s) app(s). There are also software products available that can manage cookies for you.
    </p>
    <p><strong>Your Privacy Choices and Updates</strong></p>
    <p>
        You may always update your personal information and your preferences. Limited communications may be necessary in
        order for you to participate in a particular program or service. Different programs and services may offer
        different phone numbers, links or preference managers that allow you to inform us of your updates and choices,
        including opting out of particular communications. These contact options are typically available on our Site or
        in the emails or texts we send.
    </p>
    <p><strong>Transfer of Data to Other Countries</strong></p>
    <p>
        Any information you provide to Pfizer through use of the Site may be stored and processed, transferred between
        and accessed from the Republic of Nigeria and other countries which may not guarantee the same level of
        protection of personal information as the one in which you reside. However, Pfizer will handle your personal
        information in accordance with this Privacy Policy regardless of where your personal information is
        stored/accessed.&nbsp;
    </p>
    <p><strong>Children's Privacy</strong></p>
    <p>
        We are committed to protecting the privacy of children. We do not knowingly collect personal information from
        children.
    </p>
    <p><strong>Contact Us</strong></p>
    <p>
        Please contact us if you have any questions or concerns about your personal information or require assistance in
        managing your choices.
    </p>
    <p>Pfizer Specialties Ltd</p>
    <p>Legal Department.</p>
    <p>Heritage House</p>
    <p># 21 Lugard Avenue</p>
    <p>IKoyi, Lagos</p>
    <p>Nigeria</p>
    <p>&nbsp;</p>
    <p><strong>Updates to Our Privacy Policy</strong></p>
    <p>
        <strong>Effective date; 10<sup>th</sup> July 2019</strong>
    </p>
    <p>
        From time to time, we may update and timely post revisions to this Privacy Policy. Any changes will be effective
        immediately upon the posting of the revised Privacy Policy. We encourage you to periodically review this page
        for the latest information on our privacy practices. This Privacy Policy was updated as of the effective date
        listed above.
    </p>
    <p>Copyright &copy; 2019 Pfizer. All rights reserved.</p>
    <p>
        The information provided in this Site is intended for registered healthcare professionals of the Republic of
        Nigeria only.
    </p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>

    <div mat-dialog-actions>
        <button mat-raised-button class="mat-primary mb-1" (click)="close()">Close</button>
    </div>
</div>
