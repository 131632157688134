import { Injectable } from '@angular/core';
import { AngularFirestore, Query } from '@angular/fire/firestore';
import { TherapeuticArea } from 'app/models/therapeutic-area.model';
import { Observable } from 'rxjs';

@Injectable()
export class TherapeuticAreaService {
    private collectionPath = 'therapeutic-areas';
    TherapeuticAreas$: Observable<TherapeuticArea[]>;

    constructor(private afs: AngularFirestore) {}

    getTherapeuticAreas$(pageNumber = 0, pageSize = 100): Observable<TherapeuticArea[]> {
        return this.afs
            .collection<TherapeuticArea>(this.collectionPath, (ref) => {
                let query: Query = ref;
                query = query
                    .orderBy('name', 'asc')
                    .limit(pageSize)
                    .startAfter(pageNumber * pageSize);
                return query;
            })
            .valueChanges({ idField: 'id' });
    }

    saveTherapeuticArea(therapeuticArea: TherapeuticArea) {
        if (therapeuticArea.id) {
            return this.updateTherapeuticArea(therapeuticArea);
        }
        return this.createTherapeuticArea(therapeuticArea);
    }

    createTherapeuticArea(therapeuticArea: TherapeuticArea) {
        therapeuticArea.name = therapeuticArea.name.trim();
        return this.afs.collection(this.collectionPath).add(therapeuticArea);
    }

    updateTherapeuticArea(therapeuticArea: TherapeuticArea) {
        return this.afs.doc(this.collectionPath + '/' + therapeuticArea.id).update(therapeuticArea);
    }

    deleteTherapeuticArea(id: string) {
        this.afs.doc(this.collectionPath + '/' + id).delete();
    }
}
