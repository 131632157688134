import { UserProfile } from 'app/models/user-profile.model';
import * as AuthActions from './auth.actions';

export interface State {
    isAuthenticated: boolean;
    userProfile: UserProfile;
}

const intialState: State = {
    isAuthenticated: false,
    userProfile: null,
};

export function authReducer(state = intialState, action: AuthActions.AuthActions) {
    switch (action.type) {
        case AuthActions.SET_AUTHENTICATED:
            return {
                ...state,
                isAuthenticated: true,
                userProfile: action.payload,
            };
        case AuthActions.SET_UNAUTHENTICATED:
            return {
                ...state,
                isAuthenticated: false,
                userProfile: null,
            };
        default:
            return state;
    }
}

export const getIsAuth = (state: State) => state.isAuthenticated;
