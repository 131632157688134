<div class="content">
    <p><strong>Privacy Policy</strong></p>
    <p>
        Aurobindo Pharma Limited and its subsidiaries (henceforth referred as &lsquo;APL&rsquo;) is committed to
        protecting the privacy and security of your information. The protection of your privacy in the processing of
        your personal data is an important concern to which we pay special attention during our business processes. We
        process personal data collected during visits to our websites according to the legal provisions valid for the
        countries in which the websites are maintained. This privacy notice describes the information about you that APL
        collects through this site, how that information is used, maintained, shared, protected and how you can update
        it. It also applies to all personal data received by APL from the European Economic Area (&ldquo;EEA&rdquo;) in
        any format, including electronic or paper. It is effective on the date posted below and applies to our use of
        your information after that date.
    </p>
    <ol>
        <li><strong> Personal data we collect about you</strong></li>
    </ol>
    <p>
        We collect personal data from you, for example, if you register for an event, request information, product or
        use our services, or request customer support. We may ask you to provide information such as your name, address,
        zip code, phone number, email address, job title, IP Address, and information about your device. Not all of the
        personal data APL holds about you, will always come directly from you. It may, for example, come from your
        employer, other organizations to which you belong, or a professional service provider such as your tax or
        accounting professional or attorney, if they use our Services. However, APL collects Personal data about you
        when you interact with this site and/or utilize services offered on this site. For example:<br />
        If you apply for a job or other staffing opportunity through this site, you will be asked to submit your resume
        and as well as other contact information such as your email address, phone number and mailing address. We will
        use this information to consider you for the job opening that you specify. We may also use this information to
        contact you regarding other staffing opportunities, both opportunities advertised on this site.<br />
        We may use a third party service provider to assist us with some features of our site. Our service provider will
        receive your information on our behalf and will not be permitted to use it for any other purpose.<br />
        We may also ask you for personal data in connection with other interactions you have with this Site, including
        when you answer a survey and when you report a problem with this site or services offered on this site.<br />
        We may also collect personal information from third parties such as our partners, service providers, and
        publicly available websites, to offer services we think may be of interest and to help us maintain data
        accuracy, provide, and enhance the services.<br />
        We may also collect special categories of personal data such as information concerning health when you report a
        problem with this site or services offered by us.
    </p>
    <ol start="2">
        <li><strong> How we use your personal data</strong></li>
    </ol>
    <p>
        We can only use your personal data if we have a proper reason for doing so. According to the law, we can only
        use your data for one or more of these reasons:
    </p>
    <ul>
        <li>To fulfil a contract we have with you, or</li>
        <li>If we have a legal duty to use your data for a particular reason, or</li>
        <li>When you consent to it, or</li>
        <li>When it is in our legitimate interests.</li>
    </ul>
    <p>
        Legitimate interests are our business or commercial reasons for using your data, but even so, we will not
        unfairly put our legitimate interests above what is best for you.<br />
        The use of your information is subject to the privacy notice in effect at the time of our use. APL uses
        information provided to us for our general business use. This may include the following purposes:
    </p>
    <ul>
        <li>To respond to your requests;</li>
        <li>To provide services to you including customer services issues</li>
        <li>
            To send communications to you about our or our affiliates&rsquo; current services, new services or
            promotions that we are developing, and opportunities that may be available to you;
        </li>
        <li>To alert you to new features or enhancements to our services</li>
        <li>To communicate with you about job or career opportunities about which you have inquired;</li>
        <li>To ensure that our site and our services function in an effective manner for you;</li>
        <li>To measure or understand the effectiveness of advertising and outreach.</li>
        <li>
            Marketing and events: We use personal information to deliver marketing and event communications to you
            across various platforms, such as email, telephone, text messaging, direct mail, and online. If we send you
            a marketing email, it will include instructions on how to opt out of receiving these emails in the future.
            We also maintain email preference centers for you to manage your information and marketing preferences.
            Please remember that even if you opt out of receiving marketing emails, we may still send you important
            Service information related to your accounts and subscriptions.
        </li>
        <li>
            Legal obligations: We may be required to use and retain personal information for legal and compliance
            reasons, such as the prevention, detection, or investigation of a crime; loss prevention; or fraud. We may
            also use personal information to meet our internal and external audit requirements, information security
            purposes, and as we otherwise believe to be necessary or appropriate:
        </li>
        <li>Under applicable law, which may include laws outside your country of residence;</li>
        <li>
            To respond to requests from courts, law enforcement agencies, regulatory agencies, and other public and
            government authorities, which may include such authorities outside your country of residence;
        </li>
    </ul>
    <p>
        We endeavor to collect only such information that is reasonably necessary to perform services for you or to
        respond to your inquiries.<br />
        You are responsible for ensuring that the information you provide is accurate, complete and current; we will
        take reasonable steps to ensure that the information we collect and use is relevant to its intended use.
    </p>
    <ol start="3">
        <li><strong> When we share personal data</strong></li>
    </ol>
    <p>
        APL shares or discloses personal data when necessary to provide services or conduct our business operations.<br />
        APL may, with your consent, disclose information about you to unaffiliated third-party customers of APL in
        connection with your application for staffing opportunities advertised on this site, or in connection with
        opportunities advertised on a website of one of our affiliates. For example-
    </p>
    <ul>
        <li>
            Within APL: Our businesses around the world are supported by a variety of APL teams and functions, and
            personal information will be made available to them if necessary for the provision of Services, account
            administration, sales and marketing, customer and technical support, and business and product development,
            for instance. All of our employees and contractors are required to follow our data protection and security
            policies when handling personal data. Please click here to see a list of APL locations where we may process
            personal data
        </li>
        <li>
            Our business partners: We occasionally collaborate with other organizations to deliver co-branded Services,
            provide content, or to host events, conferences, and seminars. As part of these arrangements, you may be a
            customer of both APL and our partners, and our partners and we may collect and share information about you.
            APL will handle personal data in accordance with the privacy notice.
        </li>
    </ul>
    <ol start="4">
        <li><strong> Our third-party service providers:</strong></li>
    </ol>
    <p>
        We collaborate with and are supported by service providers around the world. Personal data will be made
        available to these parties only when necessary to fulfil the services they provide to us, such as software,
        system, and platform support; direct marketing services; cloud hosting services; advertising; and order
        fulfilment and delivery. Our third-party service providers are not permitted to share or use personal data we
        make available to them for any other purpose than to provide services to us.
    </p>
    <ul>
        <li>
            Third parties for legal reasons: We will share personal data when we believe it is required, such as:<br />
            To comply with legal obligations and respond to requests from government agencies, including law enforcement
            and other public authorities, which may include such authorities outside your country of residence.<br />
            In the event of a merger, sale, restructure, acquisition, joint venture, assignment, transfer, or other
            disposition of all or any portion of our business, assets, or stock (including in connection with any
            bankruptcy or similar proceedings)<br />
            To protect our rights, users, systems, and Services.
        </li>
    </ul>
    <ol start="5">
        <li><strong> Where we store and process personal data</strong></li>
    </ol>
    <p>
        APL is a global organization, and your personal data may be stored and processed outside. We take steps to
        ensure that the information we collect is processed according to this Privacy notice and the requirements of
        applicable law wherever the data is located. APL has networks, databases, servers, systems, support, and help
        desks located throughout our offices around the world. We collaborate with third parties such as cloud hosting
        services, suppliers, and technology support located around the world to serve the needs of our business,
        workforce, and customers. We take appropriate steps to ensure that personal data is processed, secured, and
        transferred according to applicable law. In some cases, we may need to disclose or transfer your personal data
        within APL or to third parties.<br />
        When we transfer personal information from the European Economic Area to other countries in which applicable
        laws do not offer the same level of data privacy protection as in your home country, we take measures to provide
        an appropriate level of data privacy protection. In other words, your rights and protections remain with your
        data. For example, we use approved contractual clauses, multiparty data transfer agreements, intragroup
        agreements, and other measures designed to ensure that the recipients of your personal information protect it.
    </p>
    <ol start="6">
        <li><strong> How we secure personal data</strong></li>
    </ol>
    <p>
        APL takes data security seriously, and we use appropriate technologies and procedures to protect personal
        information. Our information security policies and procedures are closely aligned with widely accepted
        international standards and are reviewed regularly and updated as necessary to meet our business needs, changes
        in technology, and regulatory requirements.<br />
        For example:<br />
        Policies and procedures: APL employs reasonable technological, physical and operational security procedures
        intended to protect your Personal data from loss, misuse, alteration, or unintentional destruction. Our security
        measures are periodically reviewed and updated in an effort to provide appropriate security for all the data
        collected about you through this Site.<br />
        We place appropriate restrictions on access to personal data<br />
        We implement appropriate measures and controls, including monitoring and physical measures, to store and
        transfer data securely<br />
        Training for employees and contractors<br />
        We require privacy, information security, and other applicable training on a regular basis for our employees and
        contractors who have access to personal data and other sensitive data<br />
        We take steps to ensure that our employees and contractors operate in accordance with our information security
        policies and procedures and any applicable contractual conditions.<br />
        We require, with contracts and security reviews, our third-party vendors and providers to protect any personal
        data with which they are entrusted in accordance with our security policies and procedures
    </p>
    <ol start="7">
        <li><strong> Cookies</strong></li>
    </ol>
    <p>
        From time to time, we may, like many other commercial websites, use a standard technology called a
        &ldquo;cookie&rdquo;. A cookie is a small text file that is placed on a computer or other device and is used to
        identify the user or device and to collect information. Cookies are typically assigned to one of four
        categories, depending on their function and intended purpose: necessary cookies, performance cookies, functional
        cookies, and cookies for marketing purposes. A cookie cannot retrieve any other data from your hard drive, pass
        on computer viruses, or capture your e-mail address. Currently, websites use cookies to enhance the user&rsquo;s
        visit; in general, cookies can securely store a user&rsquo;s ID and password, personalize home pages, and
        identify which parts of a site have been visited. It is possible to set your browser to inform you when a cookie
        is being placed. This way you have the opportunity to decide whether to accept the cookie. By showing how and
        when our visitors use this Web site, this information can help us to improve our site on a continual basis.
    </p>
    <ol start="8">
        <li><strong> Children</strong></li>
    </ol>
    <p>
        This site is not intended to collect or maintain personally data from persons under 13 years old. IF YOU ARE
        UNDER 13 YEARS OF AGE, PLEASE DO NOT USE OR ACCESS THIS SITE. If APL learns that personally identifiable
        information of a person under 13 years old has been collected without verifiable parental consent, then APL will
        take the appropriate steps to delete such information.
    </p>
    <ol start="9">
        <li><strong> Your rights and your personal data</strong></li>
    </ol>
    <p>
        We respect your right to access and control your information, and we will respond to requests for information
        and, where applicable, will correct, amend, or delete your personal information.<br />
        In such cases, we will need you to respond with proof of your identity before you can exercise these rights.
    </p>
    <ul>
        <li>
            The right to access information: At any point you can contact us to request the information we hold on you
            as well as why we have that information, who has access to the information and where we obtained the
            information from. Once we have received your request we will respond within one month.
        </li>
        <li>
            There are no fees or charges for the first request but additional requests for the same data may be subject
            to an administrative fee.
        </li>
        <li>
            The right to correct and update the information: If the data we hold on you is out of date, incomplete or
            incorrect, you can inform us and your data will be updated.
        </li>
        <li>
            The right to have your information erased: If you feel that we should no longer be using your data or that
            we are illegally using your data, you can request that we erase the data we hold.<br />
            When we receive your request we will confirm whether the data has been deleted or the reason why it cannot
            be deleted (for example because we need it for our legitimate interests or regulatory purpose(s).
        </li>
        <li>
            The right to object to processing: You have the right to request that we stop processing your data. Upon
            receiving the request, we will contact you and let you know if we are able to comply or if we have
            legitimate grounds to continue to process your data. Even after you exercise your right to object, we may
            continue to hold your data to comply with your other rights or to bring or defend legal claims.
        </li>
        <li>
            The right to data portability: You have the right to request that we transfer some of your data to another
            controller. We will comply with your request, where it is feasible to do so, within one month of receiving
            your request.
        </li>
        <li>
            The right to withdraw your consent: to the processing at any time for any processing of data to which
            consent was sought. You can withdraw your consent easily by telephone, email, or by post (see Contact
            Details below).
        </li>
        <li>The right to object to the processing: of personal data where applicable.</li>
        <li>
            The right to lodge a complaint: with the Data Protection officer at&nbsp;<a href="mailto:dpo@aurobindo.com"
                >dpo@aurobindo.com</a
            >
        </li>
        <li>How long do we keep your personal data?</li>
    </ul>
    <p>
        We retain personal information for as long as we reasonably require it for legal or business purposes. In
        determining data retention periods, APL takes into consideration local laws, contractual obligations, and the
        expectations and requirements of our customers. When we no longer need personal information, we securely delete
        or destroy it.
    </p>
    <p><strong>Changes to Our Privacy notice</strong></p>
    <p>
        APL may update privacy notice from time to time. We encourage you to check our site frequently to see the
        current privacy notice so that you may stay informed of how APL is using and protecting your information.
        Whenever a change to this notice is significant, we will place a prominent notice on this site and provided an
        updated effective date.
    </p>
    <p><strong>Questions/Contact Information</strong></p>
    <p>
        If you have questions or comments regarding this privacy notice, please contact us, or at:<br />
        Address: Aurobindo Pharma Limited, Plot no. 2, Maitrivihar, Ameerpet, Hyderabad &ndash; 500038, Telangana,
        India.<br />
        Phone: +91 (40) 6672 5000 / +91 (40) 6672 1200<br />
        Email:&nbsp;<a href="mailto:dpo@aurobindo.com">dpo@aurobindo.com</a><br />
        Effective Date: 24th May 2018
    </p>
    <p>&nbsp;</p>
    <div mat-dialog-actions>
        <button mat-raised-button class="mat-primary mb-1" (click)="close()">Close</button>
    </div>
</div>
