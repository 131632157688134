<div class="content">
    <!-- <h1>Lorem Ipsum</h1>

    <h4>"Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit..."</h4>
    <h5>
        "There is no one who loves pain itself, who seeks after it and wants to have it, simply because it is pain..."
    </h5>
    <hr />
    <p>
        Sed semper tortor libero, non facilisis tortor pulvinar vitae. Pellentesque bibendum faucibus dui et aliquet.
        Cras sapien nulla, ornare eu malesuada ac, viverra sit amet ligula. Praesent interdum vel diam nec tincidunt.
        Nullam eu lacus ultrices, cursus elit vel, molestie nisl. Donec bibendum arcu et arcu interdum, a placerat justo
        blandit. Ut at erat pulvinar, facilisis ante eget, molestie eros. Etiam tempus vulputate lacus. Cras ut lectus
        elit. Nam venenatis diam et mi aliquet, vitae eleifend elit pharetra. Curabitur hendrerit, augue non lobortis
        euismod, leo diam consequat urna, in viverra turpis neque sit amet ante. Nunc eget facilisis nibh. Sed accumsan
        felis nibh, ut tincidunt magna ornare quis. Nam ut lacinia nunc.
    </p> -->

    <div mat-dialog-actions>
        <button mat-raised-button class="mat-primary mb-1" (click)="close()">Close</button>
    </div>
</div>
