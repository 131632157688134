import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
    selector: 'app-adverse-event-reporting',
    templateUrl: './adverse-event-reporting.component.html',
    styleUrls: ['./adverse-event-reporting.component.css'],
})
export class AdverseEventReportingComponent {
    constructor(private router: Router, public dialogRef: MatDialogRef<AdverseEventReportingComponent>) {}

    close() {
        this.dialogRef.close();
    }

    openAdverseEventReporting() {
        this.dialogRef.close();
        this.router.navigate(['cpd/adverse-event-reporting']);
    }
}
