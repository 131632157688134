import { Component, OnInit, Inject } from '@angular/core';
import { Presenter } from 'app/models/presenter.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-card-bio',
    templateUrl: './card-bio.component.html',
    styleUrls: ['./card-bio.component.scss'],
})
export class CardBioComponent implements OnInit {
    presenter: Presenter;
    constructor(@Inject(MAT_DIALOG_DATA) public data, public dialogRef: MatDialogRef<CardBioComponent>) {}

    ngOnInit() {
        this.presenter = this.data;
        console.log(this.data);
    }

    close() {
        this.dialogRef.close();
    }
}
