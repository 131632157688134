import { Injectable } from '@angular/core';
import { AngularFirestore, Query } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { Faq } from 'app/models/faq.model';
import { map } from 'rxjs/operators/map';
import { convertSnaps } from 'app/shared/helpers/db-utils';

@Injectable()
export class FaqService {
    private collectionPath = 'faqs';
    faqs$: Observable<Faq[]>;

    constructor(private afs: AngularFirestore) {}

    getFaqs$(pageNumber = 0, pageSize = 100): Observable<Faq[]> {
        return this.afs
            .collection<Faq>(this.collectionPath, (ref) => {
                let query: Query = ref;
                query = query
                    .orderBy('question', 'asc')
                    .limit(pageSize)
                    .startAfter(pageNumber * pageSize);
                return query;
            })
            .valueChanges({ idField: 'id' });
    }

    getActiveFaqs(): Observable<Faq[]> {
        return this.afs
            .collection(this.collectionPath)
            .snapshotChanges()
            .pipe(
                map((snaps) => {
                    let faqs = convertSnaps<Faq[]>(snaps).filter((q) => q.isActive === true);
                    return faqs;
                })
            );
    }

    saveFaq(faq: Faq) {
        if (faq.id) {
            return this.updateFaq(faq);
        }
        return this.createFaq(faq);
    }

    createFaq(faq: Faq) {
        return this.afs.collection(this.collectionPath).add(faq);
    }

    updateFaq(faq: Faq) {
        return this.afs.doc(this.collectionPath + '/' + faq.id).update(faq);
    }

    deleteFaq(id: string) {
        this.afs.doc(this.collectionPath + '/' + id).delete();
    }
}
