import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { of, Observable, Subscription } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { UserProfileService } from './user-profile.service';
import { UserProfile } from 'app/models/user-profile.model';
import { UIService } from '../app-loader/ui.service';

import { Store } from '@ngrx/store';
import * as fromRoot from '../../app.reducer';
import * as Auth from './auth.actions';
import { ConfigService } from '../config.service';
import { RepService } from '../rep.service';
import { RepDoctorService } from '../rep-doctor.service';

@Injectable()
export class AuthService implements OnDestroy {
    // userProfile: UserProfile;
    userProfile$: Observable<UserProfile>;
    subscriptions: Subscription[] = [];
    getDoctorsDefaultsSubscription: Subscription;

    // to remove and use the store
    // userProfile$: Observable<UserProfile>;
    // isLoggedIn$: Observable<boolean>;
    // isLoggedIn: boolean;
    mpNumber: string;
    currentUser$: Observable<any>;
    private currentUser: any;
    found = false;
    initialNavigationStarted = false;

    constructor(
        private router: Router,
        public afAuth: AngularFireAuth,
        private afs: AngularFirestore,
        private userProfileService: UserProfileService,
        private configService: ConfigService,
        private uiService: UIService,
        private store: Store<fromRoot.State>,
        private repService: RepService,
        private repDoctorService: RepDoctorService,
        // private navigationService: NavigationService,
        public ngZone: NgZone // NgZone service to remove outside scope warning,
    ) {
        this.currentUser$ = this.afAuth.user;

        this.router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe(() => {
            this.initialNavigationStarted = true;
        });
    }

    callAuthListenerIfRoutingIsCompleted() {
        if (this.initialNavigationStarted) {
            // Wait for the next NavigationEnd event
            this.router.events
                .pipe(
                    filter((event) => event instanceof NavigationEnd),
                    first()
                )
                .subscribe(() => {
                    this.authListener();
                });
        } else {
            // If initial navigation hasn't started, call authListener immediately
            this.authListener();
        }
    }

    // called from AppComponent on application startup
    // auth.service.ts
    authListener() {
        this.subscriptions.push(
            this.afAuth.authState.subscribe(
                (user) => {
                    if (user) {
                        this.currentUser = user;
                        this.userProfile$ = this.afs
                            .doc<UserProfile>(`userProfile/${this.currentUser.uid}`)
                            .valueChanges();

                        this.subscriptions.push(
                            this.userProfile$.subscribe(
                                (userProfile) => {
                                    if (userProfile) {
                                        this.store.dispatch(new Auth.SetAuthenticated(userProfile));
                                        this.configService.userProfile = userProfile;
                                        this.handlePostLoginNavigation();
                                    } else {
                                        // User profile doesn't exist; create a default one
                                        this.createDefaultUserProfile().then(() => {
                                            this.handlePostLoginNavigation();
                                        });
                                    }
                                },
                                (error) => {
                                    console.error('Error fetching user profile:', error);
                                }
                            )
                        );

                        if (!this.emailVerified()) {
                            this.router.navigate(['/sessions/verify-email']);
                        }
                    } else {
                        this.uiService.showSnackbar('You have successfully signed out', 'close', 3000);
                        this.currentUser = null;
                        this.store.dispatch(new Auth.SetUnauthenticated());
                        this.router.navigate(['sessions/signin']);
                    }
                },
                (error) => {
                    console.error('authListener error:', error);
                }
            )
        );
    }

    private handlePostLoginNavigation() {
        const redirectUrl = localStorage.getItem('redirectAfterLogin');
        if (redirectUrl && redirectUrl !== this.router.url) {
            localStorage.removeItem('redirectAfterLogin');
            this.router.navigateByUrl(redirectUrl).catch((err) => {
                console.error('Navigation error:', err);
                this.router.navigate(['user/profile']);
            });
        } else if (!redirectUrl && !this.router.url.includes('competition')) {
            this.router.navigate(['user/profile']);
        }
    }

    emailVerified(): boolean {
        // console.log(this.afAuth.auth.currentUser.emailVerified);
        if (this.afAuth.auth.currentUser.emailVerified) {
            // console.log('Success: Email Verified');
            // } else {
            //     console.log('Email not Verfied!');
            //     this.handleError('Email not Verfied!');
        }
        return this.afAuth.auth.currentUser.emailVerified;
    }

    signInWithEmailAndPassword(email: string, password: string) {
        this.afAuth.auth
            .signInWithEmailAndPassword(email, password)
            .then((result) => {
                this.currentUser = result;
            })
            .catch((error) => {
                window.alert(error.message);
                console.log('Something is wrong:', error.message);
            });
    }

    sendVerificationMail() {
        return this.afAuth.auth.currentUser.sendEmailVerification().then(() => {
            this.router.navigate(['/sessions/verify-email']);
            this.handleError('A verification email was sent to: ' + this.afAuth.auth.currentUser.email);
        });
    }

    sendPasswordResetEmail(email: string) {
        return this.afAuth.auth
            .sendPasswordResetEmail(email)
            .then(() => this.handleError('We have emailed you a password rest email to: ' + email))
            .catch((error) => this.handleError(error.message));
    }

    // new user registration starts here
    emailSignUp(email: string, password: string): Observable<boolean> {
        this.afAuth.auth
            .createUserWithEmailAndPassword(email, password)
            .then((result) => {
                this.currentUser = result.user;
                this.sendVerificationMail();
                return this.createDefaultUserProfile().then(() => true);
            })
            .catch((error) => {
                switch (error.code) {
                    case 'auth/email-already-in-use':
                        this.handleError(`Email address ${email} already in use.`);
                        break;
                    case 'auth/invalid-email':
                        this.handleError(`Email address ${email} is invalid.`);
                        break;
                    case 'auth/operation-not-allowed':
                        this.handleError(`Error during sign up.`);
                        break;
                    case 'auth/weak-password':
                        this.handleError(
                            'Password is not strong enough. Add additional characters including special characters and numbers.'
                        );
                        break;
                    default:
                        this.handleError(error.message);
                }
            });
        return of(false);
    }

    // After email validation and after mpNumber in use check, continue the registration process here
    registerUserProfile(
        mpNumber: string,
        market: string,
        governmentOffical: string,
        hcpType: string,
        acceptConcent,
        acceptPrivacy,
        acceptPopi,
        profileFromDb: any
    ) {
        // console.log(profileFromDb);
        // console.log('authSErvice - mpNumber:', mpNumber);
        // console.log('about to call: getRepIdForDoctorByMpNumber');
        this.repDoctorService.getRepIdForDoctorByMpNumber(mpNumber).subscribe((repDoctorDb) => {
            if (repDoctorDb === undefined) {
                // console.log('there are no doctors in the database with this mpNumber, what should I do?');
            }
            // console.log('authService - repDoctorDb:', repDoctorDb);
            this.userProfileService
                .createUserProfile(
                    this.currentUser,
                    mpNumber,
                    profileFromDb,
                    market,
                    governmentOffical,
                    hcpType,
                    acceptConcent,
                    acceptPrivacy,
                    acceptPopi,
                    repDoctorDb.repId,
                    repDoctorDb.FullName
                )
                .then(() => {
                    // console.log('>>>>User Profile Created');
                    window.location.reload();
                });
        });
    }

    getHcpDefaults(mpNumber: string, hcpType: string) {
        switch (hcpType) {
            case 'Doctor':
                return this.getDoctorsDefaults(mpNumber);
            case 'Nurse':
            case 'Pharmacist':
            case 'Clinical Pharmacist':
            case 'Internal Staff':
            case 'Rep':
                return this.getBlankDefaults(mpNumber);
            default:
                break;
        }
    }

    getRepDefaults(mpNumber: string) {
        return this.repService.getRepByMpNumber(mpNumber);
    }

    getDoctorsDefaults(mpNumber: string) {
        // Validating against the doctors database, check with MP and add trailing 0's in front
        return this.afs
            .collection('doctors', (ref) => ref.where('councilnumber1', '==', mpNumber + 'MP'))
            .valueChanges();
    }

    getBlankDefaults(mpNumber: string) {
        let temp = [
            {
                councilnumber1: '',
                country_description: '',
                email: '',
                firstname: '',
                gender: '',
                initials: '',
                language: 'English',
                lastname: '',
                physad1: '',
                physad2: '',
                physcode: '',
                physsuburb: '',
                regionname: '',
                service: 'Not Applicable',
                subregionname: '',
                tel1: '',
                tel1code: '',
                tel2: '',
                tel2code: '',
                title: '',
                town: '',
            },
        ];
        return of(temp);
    }

    // auth.service.ts
    private createDefaultUserProfile(): Promise<void> {
        const defaultProfile: UserProfile = {
            uid: this.currentUser.uid,
            email: this.currentUser.email,
            language: 'English',
            photoURL: this.currentUser.photoURL || '',
            userRole: 'User', // Set default role
            market: '',
            isActive: false,
            isSuspended: false,
            firstName: '',
            lastName: '',
            mpNumber: '',
            country: '',
            initials: '',
            physicalAddress1: '',
            physicalAddress2: '',
            physicalSuburb: '',
            physicalAddressCode: '',
            regionName: '',
            hcpType: '',
            discipline: '',
            subRegionName: '',
            cellNumber: '',
            phoneNumber: '',
            title: '',
            town: '',
            healthCareProfessional: false,
            isVerified: false,
            repId: '',
            repName: '',
            createdDate: '',
        };

        return this.afs
            .doc<UserProfile>(`userProfile/${this.currentUser.uid}`)
            .set(defaultProfile)
            .then(() => {
                console.log('Default user profile created');
                this.configService.userProfile = defaultProfile;
            })
            .catch((error) => {
                console.error('Error creating default user profile:', error);
                this.handleError('Failed to create user profile.');
            });
    }

    getBlankDefaultStatic() {
        let temp = {
            councilnumber1: '',
            country_description: '',
            email: '',
            firstname: '',
            gender: '',
            initials: '',
            language: 'English',
            lastname: '',
            physad1: '',
            physad2: '',
            physcode: '',
            physsuburb: '',
            regionname: '',
            service: 'Not Applicable',
            subregionname: '',
            tel1: '',
            tel1code: '',
            tel2: '',
            tel2code: '',
            title: '',
            town: '',
        };
        return temp;
    }

    isMpNumberisInUse(mpNumber: string, hcpType: string) {
        return this.afs
            .collection('userProfile', (ref) => ref.where('mpNumber', '==', mpNumber).where('hcpType', '==', hcpType))
            .valueChanges()
            .pipe(first());
    }

    checkIfUserHasProfileAndRedirect() {
        const currentUrl = this.router.url;

        if (currentUrl.includes('poll')) {
            // If the user is already on a poll route, do not redirect
            return;
        }

        this.subscriptions.push(
            this.afs
                .collection('userProfile', (ref) => ref.where('uid', '==', this.currentUser.uid))
                .valueChanges()
                .pipe(first())
                .subscribe(
                    (userProfile) => {
                        if (userProfile.length > 0) {
                            const redirectUrl = localStorage.getItem('redirectAfterLogin');
                            if (redirectUrl && redirectUrl !== currentUrl) {
                                localStorage.removeItem('redirectAfterLogin');
                                this.router.navigateByUrl(redirectUrl).catch((err) => {
                                    console.error('Navigation error:', err);
                                    // Redirect to default route if navigation fails
                                    this.router.navigate(['user/profile']);
                                });
                            } else if (!redirectUrl) {
                                this.router.navigate(['user/profile']);
                            }
                        } else {
                            localStorage.setItem('redirectAfterLogin', currentUrl);
                            this.router.navigate(['/sessions/verify-mp-number']).catch((err) => {
                                console.error('Navigation error:', err);
                                // Handle navigation error if needed
                            });
                        }
                    },
                    (error) => {
                        console.log('AuthService: checkIfUserHasProfileAndRedirect', error);
                    }
                )
        );
    }

    signOut() {
        this.afAuth.auth.signOut();
    }

    getPrefixForHcpType(hcpType) {
        switch (hcpType) {
            case 'Nurse':
                return 'N';
            case 'Pharmacist':
                return 'P';
            case 'Internal Staff':
                return 'AS';
            case 'Rep':
                return 'AR';
            case 'Wholesaler':
                return 'AW';
            case 'Clinical Pharmacist':
                return 'CP';
            default:
                return 'MP';
        }
    }

    getPlaceholderForHcpType(hcpType) {
        switch (hcpType) {
            case 'Nurse':
                return ' (SANC Number)';
            case 'Pharmacist':
            case 'Clinical Pharmacist':
                return ' (SAPC Number)';
            default:
                return ' (HPCSA Number)';
        }
    }

    public handleError(error) {
        this.uiService.showSnackbar(error, 'close', 3000);
    }

    ngOnDestroy() {
        this.subscriptions.forEach((s) => s.unsubscribe());
        this.getDoctorsDefaultsSubscription.unsubscribe();
    }
}
