import { Injectable } from '@angular/core';
import { AngularFirestore, Query } from '@angular/fire/firestore';
import { Observable, of } from 'rxjs';
import { RepDoctorDb } from 'app/models/rep-doctor-db.model';
import { catchError, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class RepDoctorService {
    private collectionPath = 'rep-doctor';
    // reps$: Observable<Rep[]>;

    constructor(private afs: AngularFirestore) {}

    // TODO: MUST BE FIXED , THIS HAS BEEN CHANGED
    getRepDoctors(repId: string = null, pageNumber = 0, pageSize = 10000): Observable<RepDoctorDb[]> {
        return this.afs
            .collection<RepDoctorDb>(this.collectionPath, (ref) => {
                let query: Query = ref;
                query = query
                    .orderBy('FullName', 'asc')
                    .limit(pageSize)
                    .startAfter(pageNumber * pageSize);
                if (repId) {
                    // console.log('repId: ', repId);
                    query = query.where('repId', '==', repId);
                    // to be removed *******
                    // query = query.where('NumericRegistrationNumber', '==', '');
                }
                return query;
            })
            .valueChanges({ idField: 'id' });
    }

    getRepDoctorByMpNumber(mpNumber: string): Observable<RepDoctorDb[]> {
        // console.log('searchin for mpNumber: ', mpNumber);
        return this.afs
            .collection<RepDoctorDb>(this.collectionPath, (ref) => {
                let query: Query = ref;
                // if (mpNumber) {
                query = query.where('NumericRegistrationNumber', '==', mpNumber);
                //}
                return query;
            })
            .valueChanges({ idField: 'id' });
    }

    getRepIdForDoctorByMpNumber(mpNumber: string): Observable<RepDoctorDb> {
        return this.getRepDoctorByMpNumber(mpNumber).pipe(
            map((repDoctors) => {
                if (repDoctors.length > 0) {
                    const repDoctor = repDoctors[0]; // Assuming you only need the first doctor if multiple found
                    // console.log('repDoctor Found:', repDoctor);
                    return repDoctor as RepDoctorDb; // Casting to RepDoctorDb
                } else {
                    // console.log('repDoctor Not Found');
                    // Returning a dummy object with repId and FullName as empty strings
                    return {
                        repId: '',
                        FullName: '',
                    } as RepDoctorDb;
                }
            }),
            catchError((error) => {
                console.error('Error getting repDoctor:', error);
                // Returning a dummy object with repId and FullName as empty strings
                return of({
                    repId: '',
                    FullName: '',
                } as RepDoctorDb);
            })
        );
    }

    // getRepIdForDoctorByMpNumber(mpNumber: string): Observable<RepDoctorDb> {
    //     let repDoctor = {} as RepDoctorDb;
    //     this.getRepDoctorByMpNumber(mpNumber).subscribe((repDoctors) => {
    //         console.log('.......', repDoctors);
    //         if (repDoctors.length > 0) {
    //             repDoctors.forEach((repDoctor) => {
    //                 console.log('repDoctors Found:', repDoctor);
    //                 return of(repDoctor.repId);
    //             });
    //         } else {
    //             console.log('±±±±repDoctors Not Found');
    //             return of(repDoctor);
    //         }
    //     });
    //     return of(repDoctor);
    // }

    saveRepDoctor(doctor: RepDoctorDb) {
        // console.log('saveRepDoctor; doctor: ', doctor);
        if (doctor.id) {
            return this.updateRepDoctor(doctor);
        }
        return this.createRepDoctor(doctor);
    }

    private createRepDoctor(doctor: RepDoctorDb) {
        // console.log('createRep; doctor: ', doctor);
        return this.afs.collection(this.collectionPath).add(doctor);
    }

    private updateRepDoctor(doctor: RepDoctorDb) {
        return this.afs.doc(this.collectionPath + '/' + doctor.id).update(doctor);
    }

    deleteRepDoctor(id: string) {
        this.afs.doc(this.collectionPath + '/' + id).delete();
    }
}
