<div class="content">
    <h1>Disclaimer</h1>
    <p>
        <strong><u>Aurobindo Website Disclaimer Policy</u></strong>
    </p>
    <p>
        Any Individual/Entity/Firm accessing/logging on to or using (&ldquo;Visitor&rdquo;) the website
        (www.aurobindorealty.com) has unconditionally accepted the terms and conditions of use and these constitute a
        binding and enforceable agreement between the &ldquo;Visitor&rdquo; and Aurobindo Pharma Limited
        (&ldquo;APL&rdquo;).
    </p>
    <ol>
        <li>
            The contents, information and material contained in this website are presented as general information and no
            representation or warranty is expressly or impliedly given as to its accuracy, completeness or correctness.
        </li>
    </ol>
    <ol start="2">
        <li>
            All rights, title and interest (including, but not limited to all copyrights, trademarks, and other
            intellectual property rights) are the exclusive property and are our proprietary marks of APL, they are
            protected by intellectual property laws. Visitor acknowledges that he/she/they do not own and shall not
            acquire any title, interest, or right in any manner pertaining to the intellectual property rights
            absolutely belonging to APL.
        </li>
    </ol>
    <ol start="3">
        <li>
            No Visitor shall use, copy, reproduce, distribute, initiate, publish, display, modify, create derivative
            works or database, use, transmit, upload, exploit, sell or distribute the same in whole or in part or any
            part thereof without prior express written permission from APL. Facility to print an article or portion of
            text or material on this website through computer/electronic device does not tantamount to prior written
            consent.
        </li>
    </ol>
    <ol start="4">
        <li>
            The Visitor is presumed to have read the terms and conditions of the website and is deemed to have agreed,
            understood, and accepted unconditionally all the terms, conditions, procedure and risks of accessing/logging
            onto the website and cannot claim, at any instance, ignorance of any or all of them. All relationships of
            any visitor of this website wheresoever situated is governed by and in accordance with the INDIAN laws and
            jurisdiction of Courts situated at Hyderabad, Telangana State, INDIA.
        </li>
    </ol>
    <ol start="5">
        <li>
            APL uses all diligence, skill and expertise available to provide information on this website but does not
            accept or undertake any express or implied warranty of any nature whatsoever and APL disclaims all or any
            errors and mistakes to the fullest extent. APL does not warrant that the information offered will be
            error-free, or that the defects will be corrected, or that this site or the server that makes it available
            are or will be free of viruses or other harmful components. APL shall not be under any obligation to ensure
            compliance or handle complaints.
        </li>
    </ol>
    <ol start="6">
        <li>
            APL and its respective subsidiaries and affiliates, and their respective officers, directors, partners,
            employees, agents, managers, trustees, representatives or contractors of any of them, related thereto and
            any successors or assigns of any of the foregoing shall not be liable for any direct, indirect, actual,
            punitive, incidental, special, consequential damages or economic loss whatsoever, arising from or related to
            the use of or reliance on this Website.
        </li>
    </ol>
    <ol start="7">
        <li>
            The Visitor has, by the act of accessing/logging onto the website and/or submitting information or giving
            his name, address, email addresses as identification to APL through the website, on phone, fax or e-mail,
            deemed to have consented and has expressly and irrevocably authorized APL to use, reveal, analyse or display
            and transmit all information and documents as may be required by it. The Visitor represents and warrants
            that the Visitor has provided true, accurate, current and complete information about himself and if any
            information is found to be untrue, inaccurate, not current or incomplete, APL has the right to take any
            action it deems appropriate without any limitation.
        </li>
    </ol>
    <ol start="8">
        <li>
            The Visitor represents and warrants that he/she is fully aware of the laws of the country/state where the
            Visitor resides in and also those of India particularly those governing use, sale, lease, transfer of real
            estate and the visitor is neither violating nor attempting to violate any law.
        </li>
    </ol>
    <ol start="9">
        <li>
            Notwithstanding anything stated hereinabove or in this website, it is clarified, understood and agreed that
            APL through this website does not intend to make any offer, proposal or contract as per prevailing laws in
            India or any similar or relevant law in the country of residence or access of the visitor.
        </li>
    </ol>
    <ol start="10">
        <li>
            APL has the right to reproduce, monitor, disclose any transmission or information received and made to this
            website. Visitors may be sent information or contacted through the email addresses, phone numbers and postal
            addresses provided by the visitor on the website. Any Visitor who may not desire to receive email from APL
            may give clear instructions.
        </li>
    </ol>
    <ol start="11">
        <li>
            The Visitor shall not use or post any computer programs in connection with his/her use of the website that
            contain destructive features such as viruses anomalies, self-destruct mechanisms, time/logic bombs, worm,
            Trojan horses etc.
        </li>
    </ol>
    <ol start="12">
        <li>
            Notwithstanding anything, in no event shall APL, their promoters, directors, employees and agents be liable
            to the visitor for any or all damages, losses and causes of action (including but not limited to
            negligence), errors, injury, whether direct, indirect, consequential or incidental, suffered or incurred by
            any person/s or due to any use and/or inability to use this site or information or its links, hyperlinks,
            action taken or abstained or any transmission made through this website.
        </li>
    </ol>
    <ol start="13">
        <li>
            Any links to off-site pages or other sites may be accepted by the visitor at his/her option and own risk and
            APL assumes no liability for and shall be held harmless from any resulting damages. APL strongly recommends
            that the visitor carefully reads the terms and conditions of such linked site(s).
        </li>
    </ol>
    <ol start="14">
        <li>
            APL reserves the right to terminate, revoke, modify, alter, add, and delete any one or more of the terms and
            conditions of the website. APL shall be under no obligation to notify the Visitor of such amendment to the
            terms and conditions from time to time and the Visitor shall be bound by such amended terms and conditions.
        </li>
    </ol>
    <ol start="15">
        <li>
            When you voluntarily send us electronic mail, we will keep a record of this information so that we can
            respond to you. We only collect information from you when you register on our site or fill out a form. Also,
            when filling out a form on our site, you may be asked to enter your: name, e-mail address or phone number.
            You may, however, visit our site anonymously. In case you have submitted your personal information and
            contact details, we reserve the rights to Call, SMS, Email or WhatsApp about our products and offers, even
            if your number has DND activated on it.
        </li>
    </ol>
    <div mat-dialog-actions>
        <button mat-raised-button class="mat-primary mb-1" (click)="close()">Close</button>
    </div>
</div>
