import { NgModule, ErrorHandler } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GestureConfig } from '@angular/material/core';
import {
    PerfectScrollbarModule,
    PERFECT_SCROLLBAR_CONFIG,
    PerfectScrollbarConfigInterface,
} from 'ngx-perfect-scrollbar';

import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { InMemoryDataService } from './shared/inmemory-db/inmemory-db.service';

import { rootRouterConfig } from './app.routing';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';

import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ErrorHandlerService } from './services/error-handler.service';
import { AuthService } from './services/auth/auth.service';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';

import { environment } from 'environments/environment';
import { UserProfileService } from './services/auth/user-profile.service';
import { SnackBarService } from './services/snackBar.service';
import { AgoraApiService } from './services/agora-api.service';
import { BrandService } from './services/brand.service';
import { GoogleAnalyticsService } from './services/google-analytis';
import { UIService } from './services/app-loader/ui.service';

import { StoreModule } from '@ngrx/store';
import { reducers } from './app.reducer';
import { ConfigService } from './services/config.service';
import { TherapeuticAreaService } from './services/therapeutic-area.service';
import { FaqService } from './services/faq.service';
import { RepService } from './services/rep.service';
import { AdverseEventService } from './services/adverse-event.service';
import { HcpLogService } from './services/hcp-log.service';
import { ProductComplaintService } from './services/product-complaint.service';
import { AngularFireDatabaseModule } from '@angular/fire/database';

// import { PdfViewerModule } from 'ng2-pdf-iewer';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient);
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
};

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        SharedModule,
        HttpClientModule,
        PerfectScrollbarModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFireAnalyticsModule,
        AngularFireAuthModule,
        AngularFirestoreModule.enablePersistence(),
        AngularFireStorageModule,
        AngularFireDatabaseModule,
        InMemoryWebApiModule.forRoot(InMemoryDataService, { passThruUnknownUrl: true }),
        StoreModule.forRoot(reducers),
        RouterModule.forRoot(rootRouterConfig, { useHash: false }),
    ],
    declarations: [AppComponent],
    providers: [
        GoogleAnalyticsService,
        AuthService,
        UserProfileService,
        SnackBarService,
        AgoraApiService,
        TherapeuticAreaService,
        BrandService,
        FaqService,
        RepService,
        AdverseEventService,
        ProductComplaintService,
        HcpLogService,
        UIService,
        ConfigService,
        { provide: ErrorHandler, useClass: ErrorHandlerService },
        { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },
        { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
