import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable()
export class SnackBarService {
    constructor(private snackBar: MatSnackBar) {
        // let config = new MatSnackBarConfig();
        // config.panelClass = 'custom-panel-class';
    }

    public presentSuccess(message) {
        this.snackBar.open(message, 'close', { duration: 6000, panelClass: 'green-snackbar' });
    }

    public presentError(message) {
        this.snackBar.open(message, 'close', { duration: 6000, panelClass: 'red-snackbar' });
    }

    public presentMessage(message) {
        this.snackBar.open(message, 'close', { duration: 6000, panelClass: 'blue-snackbar' });
    }
}
