<div class="content">
    <h1>Adverse Event Reporting</h1>
    <br />

    <!-- <h4>"Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit..."</h4>
    <h5>
        "There is no one who loves pain itself, who seeks after it and wants to have it, simply because it is pain..."
    </h5> -->

    <h3>To report and adverse Event directly, please use the contact details below.</h3>
    <h3>Alernatively you can log an adverse event by clicking on 'Adverse Event Reporting' in the Menu.</h3>
    <br />

    <div><b>Affiliate: </b> Aurobindo Pharma South Africa</div>
    <div><b>Name:</b> Kudzai Chiumburu</div>
    <div><b>Role:</b> Responsible Person for PV</div>
    <div><b>Office telephone number:</b> 011 867 9132</div>
    <div><b>Mobile number:</b> 078 932 3804</div>
    <div><b>Email address:</b> Kudzai.Chiumburu@aurobindo.com</div>
    <div><b>PV dedicated email:</b> pv.za@aurobindo.com</div>

    <br />

    <div><b> Affiliate:</b> Aurogen South Africa</div>
    <div><b> Name:</b> Leshania Pillay</div>
    <div><b> Role:</b> Responsible Person for PV</div>
    <div><b> Office telephone number:</b> 011 867 9107</div>
    <div><b> Mobile number:</b> 061 489 5377</div>
    <div><b> Email address:</b> leshania.pillay@aurogensa.co.za</div>
    <div><b> PV dedicated email:</b> pv.za@aurobindo.com</div>
    <br />

    <div mat-dialog-actions>
        <button mat-raised-button class="mat-primary mb-1" (click)="close()">Close</button>
    </div>
</div>
