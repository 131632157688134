import { Component, OnInit, AfterViewInit, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';

// import { ThemeService } from './shared/services/theme.service';

import { filter } from 'rxjs/operators';
// import { LayoutService } from './shared/services/layout.service';

import { Observable } from 'rxjs/internal/Observable';
import { AuthService } from './services/auth/auth.service';
import { RoutePartsService } from './services/route-parts.service';

declare let gtag: Function;
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, AfterViewInit {
    appTitle = 'Auro Space';
    pageTitle = '';

    items: Observable<any[]>;
    constructor(
        public title: Title,
        private router: Router,
        private activeRoute: ActivatedRoute,
        private routePartsService: RoutePartsService,
        private authService: AuthService // private themeService: ThemeService, // private layout: LayoutService,
    ) {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                gtag('config', 'G-9KN61X08MJ', {
                    page_path: event.urlAfterRedirects,
                });
            }
        });
    }

    ngOnInit() {
        this.authService.callAuthListenerIfRoutingIsCompleted();
        this.changePageTitle();
    }

    ngAfterViewInit() {}

    changePageTitle() {
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((routeChange) => {
            const routeParts = this.routePartsService.generateRouteParts(this.activeRoute.snapshot);
            if (!routeParts.length) {
                return this.title.setTitle(this.appTitle);
            }
            // Extract title from parts;
            this.pageTitle = routeParts
                .reverse()
                .map((part) => part.title)
                .reduce((partA, partI) => {
                    return `${partA} > ${partI}`;
                });
            this.pageTitle += ` | ${this.appTitle}`;
            this.title.setTitle(this.pageTitle);
        });
    }
}

// google analytics information:
// https://www.dottedsquirrel.com/google-analytics-angular/
