import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-disclaimer',
    templateUrl: './disclaimer-india.component.html',
    styleUrls: ['./disclaimer-india.component.css'],
})
export class DisclaimerIndiaComponent implements OnInit {
    constructor(public dialogRef: MatDialogRef<DisclaimerIndiaComponent>) {}

    ngOnInit(): void {}

    close() {
        this.dialogRef.close();
    }
}
